import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import confirmBox from 'utils/confirmBox';
import { useState } from 'react';
import EditDurationPopup from './EditDurationPopup';
import Axios from 'config/api';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';

const DurationsTable = ({ settings , setSettings }) => {
    const [showEditDurationPopup , setShowEditDurationPopup] = useState(false);
    const [selectedDuration , setSelectedDuration] = useState(null);
    const [deleteLoading , setDeleteLoading] = useState(false);
    const { user } = useSelector(state => state.auth);


    const deleteHandler = ( durationId ) => {
        const message = 'Are you sure? You want to delete this duration?';
        const onYesClick = async () => {
            try {
                setDeleteLoading(true);
    
                const { data : { data : { message, doc } } } = await Axios.delete(`/setting/duration/${durationId}` , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                setSettings(prev => (doc));
                toast.success(message);
                setDeleteLoading(false);
                setSelectedDuration(null)
            } catch (error) {
                setDeleteLoading(false);
                toastError(error);
            }
        }

        confirmBox({ message , onYesClick })
    }

    return (
        <div>
            <div className="shadow-bg overflow-x-auto rounded-lg">
                <Table className="w-full table-auto overflow-x-auto">
                    <Thead className="border-b border-b-gray-300 text-sm text-primary">
                        <Tr className=' '>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Duration
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Interest Rate
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Actions
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody className='text-sm '>
                    {
                        settings?.durations?.sort((a, b) => a.duration - b.duration)?.map((item , i) => (
                            <Tr
                            key={item?._id} 
                            className="border-b border-b-gray-300 transition duration-300 ease-in-out "
                            >
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    {item?.duration} {item?.duration > 1 ? 'Months' : "Month"}
                                </Td>
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    {item?.interestRate}%
                                </Td>                         
                                <Td className=" text-gray-900  px-6 py-4 whitespace-nowrap ">
                                    <div className="flex items-center justify-center gap-4 text-2xl">

                                        <button 
                                        className='text-green-500' 
                                        data-tooltip-id="edit-duration"
                                        data-tooltip-content="Edit"
                                        onClick={() => {
                                            setSelectedDuration(item)
                                            setShowEditDurationPopup(true);
                                        }}
                                        >
                                            <i className="uil uil-edit-alt"></i>
                                            <Tooltip 
                                            id='edit-duration'
                                            style={{ fontSize : 15 }} 
                                            />
                                        </button>
                                        {
                                            deleteLoading && selectedDuration?._id === item?._id 
                                            ? 
                                                <ClipLoader size={20} />
                                            :
                                                <button 
                                                className='text-red-500' 
                                                data-tooltip-id="delete-duration"
                                                data-tooltip-content="Delete"
                                                onClick={() => {
                                                    setSelectedDuration(item);
                                                    deleteHandler(item?._id);
                                                }}
                                                >
                                                    <i className="uil uil-trash"></i>
                                                    <Tooltip 
                                                    id='delete-duration'
                                                    style={{ fontSize : 15 }} 
                                                    />
                                                </button>
                                        }
                                    </div>
                                </Td>
                            </Tr>
                            ))
                    }
                    
                    </Tbody>
                </Table>
            </div>
            {
                showEditDurationPopup && <EditDurationPopup 
                setShowPopup={setShowEditDurationPopup} 
                durationToEdit={selectedDuration} 
                setSettings={setSettings}
                />
            }
        </div>
    )
}

export default DurationsTable