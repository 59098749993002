import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import LoadingButton from 'components/global/LoadingButton'
import FormControl from 'components/global/form/FormControl'
import Axios from 'config/api'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import toastError from 'utils/toastError'
import notificationValidations from 'validations/notificationValidations'

const initValues = {
    title : '' , 
    description : '' , 
}

const SendNotification = () => {
    const navigate = useNavigate();
    const [loading , setLoading] = useState(false);


    const { user } = useSelector(state => state.auth);

    const handleSubmit = async (formData , { resetForm }) => {
        try {
            setLoading(true);
            const { data : { data : { message  } } } = await Axios.post('/notification' , formData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);
            navigate('/notifications');
            resetForm();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }



    return (
        <Layout>
            <div className="flex items-center justify-between">
                <Heading title='Send Notificaion' />
                <BackBtn />
            </div>
            <Formik
            initialValues={initValues}
            validationSchema={notificationValidations}
            onSubmit={handleSubmit}
            >
                {
                    formik => {
                        return (
                            <Form className='shadow-bg p-4 gray-border flex flex-col gap-4 mt-4'>
                                <FormControl
                                control='input'
                                label='Title'
                                placeholder='Enter notification title'
                                name='title'
                                formik={formik}
                                />
                                <FormControl
                                control='textarea'
                                label='Description'
                                placeholder='Write about notification'
                                name='description'
                                formik={formik}
                                />
                                <div className="mt-4">
                                    <button 
                                    className="btn-primary py-2 px-12"
                                    disabled={loading || !formik.isValid}
                                    >
                                        {
                                            loading 
                                            ? 
                                                <LoadingButton />
                                            :  
                                                'Send'
                                        }
                                    </button>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
        </Layout>
    )
}

export default SendNotification