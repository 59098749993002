import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import ImagePopup from 'components/global/ImagePopup'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import SelfieImage from 'components/profileRequests/SelfieImage'
import KycDetails from 'components/userManagement/userDetails/KycDetails'
import PersonalDetails from 'components/userManagement/userDetails/PersonalDetails'
import ProfessionalDetails from 'components/userManagement/userDetails/ProfessionalDetails'
import Axios from 'config/api'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ClipLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import { setDocDetails } from 'redux/reducers/profileRequestsReducer'
import confirmBox from 'utils/confirmBox'
import fetcher from 'utils/fetcher'
import toastError from 'utils/toastError'

const ProfileRequestDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { docDetails } = useSelector(state => state.profileRequest);
    const { showImagePopup } = useSelector(state => state.imagePopup);

    const [loanLimit , setLoanLimit] = useState('');
    const [updateLoading , setUpdateLoading] = useState(false);

    const querykey = ['fetch-profile-request-detail' , id];
    const { isLoading , data } = useQuery(querykey , () => {
        return fetcher(`/user-details/${id}` , user)
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { doc } } } = data;
            dispatch(setDocDetails(doc));
            setLoanLimit(doc?.loanLimit || '')
        }
    } , [data]);


    const updatehandler = async (status) => {
        const body = { status }
        if(status === 'approved') {
            if(!loanLimit) return toast.error('Please set loan limit to approve.')
            body.loanLimit = loanLimit;
        } 
        const message = `Are you sure? You want to ${status} this profile?`;
        const onYesClick = async () => {
            try {
                setUpdateLoading(true);
                const { data : { data : { message , doc } } } = await Axios.put(`/user-details/${id}` , body , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                dispatch(setDocDetails(doc));
                toast.success(message);
                setUpdateLoading(false);
            } catch (error) {
                setUpdateLoading(false);
                toastError(error);
            }
        }

        confirmBox({ message , onYesClick });
    }


    return (
        <Layout>
            <div className='flex items-center justify-between gap-4 sm:flex-row flex-col-reverse'>
                <Heading title={'Profile Details'} showIcon={false} />
                <BackBtn />
            </div>
            {
                isLoading 
                ? 
                    <Loader />
                : 
                    <div className='mt-6 flex flex-col gap-4'>
                        <SelfieImage />                     
                        <KycDetails docDetails={docDetails} />
                        <PersonalDetails docDetails={docDetails}  />
                        <ProfessionalDetails docDetails={docDetails}  />
                        <div className="shadow-bg p-4">
                            <div className="form-row">
                                <label>Set Loan Limit</label>
                                <input 
                                type='number'
                                placeholder='set user loan limit amount'
                                onChange={e => setLoanLimit(e.target.value)}
                                value={loanLimit}
                                required
                                />
                            </div>
                            {
                                docDetails?.status === 'pending' && 
                                <div className='flex items-center gap-4 sm:flex-row flex-col mt-8 justify-end'>
                                    {
                                        updateLoading
                                        ? 
                                            <ClipLoader size={20} />
                                        : 
                                            <>
                                                <button 
                                                className="bg-green-500 hover:bg-green-600 py-2 px-6 text-white rounded-md"
                                                onClick={() => updatehandler('approved')}
                                                disabled={updateLoading}
                                                >
                                                    Approve
                                                </button>
                                                <button 
                                                className="bg-red-500 hover:bg-red-600 py-2 px-6 text-white rounded-md"
                                                onClick={() => updatehandler('declined')}
                                                disabled={updateLoading}
                                                >
                                                    Declined
                                                </button>
                                            </>
                                    }
                                </div>
                            }
                        </div>
                    </div>
            }

            {
                showImagePopup ? <ImagePopup /> : ''
            }
        </Layout>
    )
}

export default ProfileRequestDetails