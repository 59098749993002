import LoanRequestsTable from 'components/dashboard/LoanRequestsTable';
import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader';
import Cards from 'components/loanPortfolio/Cards';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import fetcher from 'utils/fetcher';


const LoanPortfolio = () => {
    const { user } = useSelector(state => state.auth);
    const [loanPortfolio, setLoanPortfolio] = useState({});
    const [recentLoanRequests, setRecentLoanRequests] = useState([]);

    const queryKey = ['fetch-loan-portfolio-data']
    const { isLoading, data } = useQuery(queryKey, () => {
        return fetcher(`/admin/loan-portfolio`, user)
    });

    useEffect(() => {
        if (data) {
            const { data : { data : {
                totalNPA ,
                recentLoanRequests ,
                loanPortfolio
            }
            } } = data;
            setLoanPortfolio({
                ...loanPortfolio ,
                totalNPA
            });
            setRecentLoanRequests(recentLoanRequests);
        }
    }, [data])

    return (
        <Layout>
            <div className="flex items-center justify-between">
                <Heading title='Loan Portfolio' showIcon={false} />
                <BackBtn />
            </div>
            <div className='mt-4'>
            {
                isLoading
                ? 
                    <Loader />
                : 
                    <>
                        <Cards 
                        loanPortfolio={loanPortfolio}
                        setLoanPortfolio={setLoanPortfolio}
                        />
                        <div className='mt-10'>
                            <h3 className='heading'>Recent Loan Requests</h3>
                            <div className="mt-3">
                                {
                                    recentLoanRequests?.length > 0 
                                    ? 
                                        <LoanRequestsTable loans={recentLoanRequests} />
                                    : 
                                        <ItemNotFound message='No request found.' />
                                }
                            </div>
                        </div>
                    </>
            }
            </div>
        </Layout>
    )
}

export default LoanPortfolio