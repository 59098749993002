import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import ImagePopup from 'components/global/ImagePopup'
import ItemNotFound from 'components/global/ItemNotFound'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import KycDetails from 'components/userManagement/userDetails/KycDetails'
import PersonalDetails from 'components/userManagement/userDetails/PersonalDetails'
import ProfessionalDetails from 'components/userManagement/userDetails/ProfessionalDetails'
import UserInfo from 'components/userManagement/userDetails/UserInfo'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { setDocDetails } from 'redux/reducers/userReducer'
import fetcher from 'utils/fetcher'

const UserDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();

    const { user } = useSelector(state => state.auth);
    const { docDetails } = useSelector(state => state.user);
    const { showImagePopup } = useSelector(state => state.imagePopup);

    const [loanLimit , setLoanLimit] = useState('');
    const [updateLoading , setUpdateLoading] = useState(false);

    const querykey = ['fetch-user-detail' , id];
    const { isLoading , data } = useQuery(querykey , () => {
        return fetcher(`/user/${id}` , user)
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { doc } } } = data;
            dispatch(setDocDetails(doc));
        }
    } , [data]);


    return (
        <Layout>
            <div className="flex items-center justify-between">
                <Heading title='User Details' icon='user' />
                <BackBtn />
            </div>
            {
                isLoading 
                ? 
                    <Loader />
                : 
                docDetails
                ?
                    <div className='flex flex-col gap-6 mt-4'>
                        <UserInfo userDetails={docDetails} />
                        {
                            docDetails?.userDetails
                            ? 
                                <>
                                    <KycDetails docDetails={docDetails?.userDetails} />
                                    <PersonalDetails docDetails={docDetails?.userDetails} />
                                    <ProfessionalDetails docDetails={docDetails?.userDetails} />
                                </>
                            : 
                                <ItemNotFound message='User has not added his profile details yet.' />
                        }
                    </div>
                : 
                    <ItemNotFound message='User not found.' />

            }
            {
                showImagePopup ? <ImagePopup /> : ''
            }
        </Layout>
    )
}

export default UserDetails