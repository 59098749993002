import * as Yup from 'yup';

const loginValidations = Yup.object({
    phone : Yup.string()
    .min(10, 'Phone should have at least 10 numbers')
    .max(10, 'Phone should not exceed 10 numbers')
    .required('Phone is required')
    .matches(
        /^[6-9]\d{9}$/, 
        'Invalid phone no.'
    ) ,
    
    // password: Yup.string()
    //     .min(8, 'Password should have at least 8 characters')
    //     .required('Password is required'),
});

export default loginValidations;