import Input from 'components/global/Input'
import { baseURL } from 'config/api';

const BusinessDetails = ({ docDetails }) => {
    return (
        <div className='flex flex-col gap-4 mt-4'>
            <div className="flex gap-4 md:flex-row flex-col">
                <Input 
                label={'Occupation'}
                value={docDetails?.professionalDetails?.occupationType}
                readOnly
                />
                <Input 
                label={'Company Name'}
                value={docDetails?.professionalDetails?.companyName}
                readOnly
                />
            </div>
            <div className="flex gap-4 md:flex-row flex-col">
                <Input 
                label={'Office Address'}
                value={docDetails?.professionalDetails?.officeAddress}
                readOnly
                />
                <Input 
                label={'City'}
                value={docDetails?.professionalDetails?.city}
                readOnly
                />
            </div>
            <div className="flex gap-4 md:flex-row flex-col">
                <Input 
                label={'State'}
                value={docDetails?.professionalDetails?.state}
                readOnly
                />
                <Input 
                label={'Pin Code'}
                value={docDetails?.professionalDetails?.pinCode}
                readOnly
                />
            </div>
            <div className="flex gap-4 md:flex-row flex-col">
                <Input 
                label={'Monthly Turnover'}
                value={docDetails?.professionalDetails?.monthlyTurnover}
                readOnly
                />
                <div className="flex-1 form-row">
                    <label>Bank Statement</label>
                    <a 
                    href={`${baseURL + docDetails?.professionalDetails?.bankStatement}`}
                    target='_blank'
                    >
                        <button className='bg-blue-500 hover:bg-blue-600 py-2 px-6 rounded-md text-white w-fit'>
                            View Bank Statment
                        </button>
                    </a>
                    {/* <img 
                    src={baseURL + docDetails?.professionalDetails?.bankStatement} 
                    alt={docDetails?.kyc?.name} 
                    className='rounded-md border border-gray-300'
                    /> */}
                </div>
            </div>
        </div>
    )
}

export default BusinessDetails