import Layout from 'components/global/Layout'
import React, { useRef , useState } from 'react';
import AvatarImg from 'assets/images/avatar.png';
import fileChangeHandler from 'utils/fileChangeHandler';
import Heading from 'components/global/Heading';
import BackBtn from 'components/global/BackBtn';
import { useDispatch, useSelector } from 'react-redux';
import { baseURL } from 'config/api';
import isBase64 from 'utils/isBase64';
import { updateProfile } from 'redux/actions/authActions';
import LoadingButton from 'components/global/LoadingButton';
import { toast } from 'react-toastify';

const Profile = () => {
    const imgRef = useRef(null);
    const dispatch = useDispatch();
    const { user , updateLoading } = useSelector(state => state.auth);
    const [image , setImage] = useState(baseURL + user?.image);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if(file) {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                setImage(reader.result);
            }
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = {};
        if (isBase64(image)) {
            dispatch(updateProfile({ image }))
        }else {
            toast.info('You have not made any changes to save.')
        }
    }


    return (
        <Layout>
            <div className="flex items-center justify-between">
                <Heading title='Profile' icon='pen' />
                <BackBtn />
            </div>
            <div className='shadow-bg border border-gray-300 sm:p-6 py-4 px-3 mt-3'>
                <form 
                className='flex flex-col gap-2'
                onSubmit={handleSubmit}
                >
                    <div className='flex items-center justify-center'>
                        <div className="relative border p-4 border-gray-500 rounded-full">
                            <div className='w-[100px] h-[100px] rounded-full '>
                                <input 
                                type="file" 
                                accept='image/*'
                                hidden 
                                ref={imgRef} 
                                onChange={handleFileChange}
                                />
                                <img 
                                src={image || AvatarImg} 
                                alt={'user'}
                                className='w-full h-full rounded-full object-cover' 
                                />
                            </div>
                            <div 
                            className='absolute bottom-0 right-4 text-2xl  cursor-pointer'
                            onClick={() => imgRef?.current?.click()}
                            >
                                <i className="uil uil-camera-plus"></i>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-2 sm:flex-row flex-col mt-10'>
                        <div className="form-row">
                            <label>Full Name</label>
                            <input 
                            type="text" 
                            placeholder='Your full name'
                            value={user?.username}
                            readOnly
                            disabled
                            />
                        </div>
                        <div className="form-row">
                            <label>Email</label>
                            <input 
                            type="text" 
                            placeholder='Your full name'
                            value={user?.email}
                            readOnly
                            disabled
                            />
                        </div>
                    </div>
                    <div className='flex gap-4 sm:flex-row flex-col'>
                        <div className="form-row">
                            <label>Phone</label>
                            <input 
                            type="text" 
                            value={user?.phone}
                            disabled
                            readOnly
                            />
                        </div>
                    </div>
                    <div className="flex justify-end mt-4">
                        <button 
                        className="btn-primary py-3 px-12"
                        type='submit'
                        disabled={updateLoading}
                        >
                            {
                                updateLoading 
                                ? 
                                    <LoadingButton />
                                : 
                                    'Update'
                            }
                        </button>
                    </div>
                    <div className="error-msg sm:mt-0 mt-4">
                        Only profile picture is editable.
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default Profile