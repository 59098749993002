import RequestStatus from 'components/global/RequestStatus';
import moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Link, useLocation } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Pagination from 'components/global/pagination';
import formatAmount from 'utils/formatAmount';
import { useSelector } from 'react-redux';
import { baseURL } from 'config/api';
import { setCurrentPage } from 'redux/reducers/loanReducer';

const LoansTable = () => {
    const { docs , pages , currentPage } = useSelector(state => state.loan);


    return (
        <div>
            <div className="shadow-bg overflow-x-auto rounded-lg">
                <Table className="w-full table-auto overflow-x-auto">
                    <Thead className="border-b border-b-gray-300 text-sm text-primary">
                        <Tr className=' '>
                            <Th scope="col" className=" font-medium px-6 py-4 text-left">
                                username/phone
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Amount
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Duration
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Interest Rate
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Initiated At
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Status
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Actions
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody className='text-sm '>
                    {
                        docs?.map((item , i) => (
                            <Tr
                            key={item?._id} 
                            className="border-b border-b-gray-300 transition duration-300 ease-in-out "
                            >
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    <div className='flex flex-col gap-1'>
                                        <Link to={`/user-management/users/${item?.user?._id}`} className='text-secondary underline'>
                                            {item?.user?.username}
                                        </Link>
                                        <span>
                                            {item?.user?.phone}
                                        </span>
                                    </div>
                                </Td>
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    {formatAmount(item?.loanAmount)}
                                </Td>
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    {item?.duration} {item?.duration > 1 ? 'Months' : 'Month'}
                                </Td>
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    {item?.interestRate}%
                                </Td>
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    {moment(item?.createdAt).format('DD MMM YYYY hh:mm A')}
                                </Td>                         
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    <div className="flex sm:items-center sm:justify-center justify-end">
                                        <RequestStatus status={item?.status} />
                                    </div>
                                </Td> 
                                <Td className=" text-gray-900  px-6 py-4 whitespace-nowrap ">
                                    <div className="flex items-center justify-center gap-4 text-xl ">
                                        <Link 
                                        to={`/transactions/details/${item?._id}`}
                                        className='text-primary' 
                                        data-tooltip-id="view-user-details"
                                        data-tooltip-content="View Details"
                                        >
                                            <i className="uil uil-eye"></i>
                                            <Tooltip 
                                            id='view-user-details'
                                            style={{ fontSize : 15 }} 
                                            />
                                        </Link>


                                    </div>
                                </Td>
                            </Tr>
                            ))
                    }
                    
                    </Tbody>
                </Table>
            </div>
            <div className="mt-2">
                <Pagination
                pageCount={pages}
                currentPage={currentPage}
                setPage={setCurrentPage}
                />
            </div>
        </div>
    )
}

export default LoansTable