import React from 'react'
import Header from './header'
import Sidebar from './sidebar'

const Layout = ({ children }) => {
    return (
        <div className='space'>
            <Sidebar />
            <Header />
            <div className='sm:px-8 px-2 py-6'>
                {children}
            </div>
        </div>
    )
}

export default Layout