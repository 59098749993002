import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import Layout from 'components/global/Layout';
import Search from 'components/global/Search';
import UsersTable from 'components/userManagement/UsersTable';
import { setCurrentPage, setDocs, setDocsCount, setDuration, setKeyword, setPages } from 'redux/reducers/userReducer';
import fetcher from 'utils/fetcher';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import Loader from 'components/global/Loader';
import ItemNotFound from 'components/global/ItemNotFound';

const Users = () => {
    const dispatch = useDispatch();

    const { 
        docs : users , currentPage, duration , keyword 
    } = useSelector((state => state.user));
    const { user } = useSelector(state => state.auth);

    const queryKey = ['fetch-users' , duration , currentPage , keyword]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/user?page=${currentPage}&duration=${duration}&keyword=${keyword}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , pages , page , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docsCount));
        }
    }, [data]);

    const searchFetcher = async (value) => {
        dispatch(setKeyword(value))
    }

    return (
        <Layout>
            <div className="flex items-center justify-between">
                <Heading title='All Users' icon='users-alt' />
                <BackBtn />
            </div>
            <div className='mt-6'>
                <Search fetcher={searchFetcher} />
            </div>
            <div className='mt-3'>
                {
                    isLoading 
                    ? 
                        <Loader />
                    : 
                    users?.length > 0 
                    ? 
                        <UsersTable />
                    : 
                        <ItemNotFound message='No user found.' />
                }
            </div>
        </Layout>
    )
}

export default Users;