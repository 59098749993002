import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import userReducer from "./reducers/userReducer";
import notificationReducer from "./reducers/notificationReducer";
import profileRequestsReducer from "./reducers/profileRequestsReducer";
import loanReducer from "./reducers/loanReducer";
import communicationReducer from "./reducers/communicationReducer";
import imagePopupReducer from "./reducers/imagePopupReducer";


const store = configureStore({
    reducer: {
        auth : authReducer ,
        user : userReducer ,
        notification : notificationReducer , 
        profileRequest : profileRequestsReducer , 
        loan : loanReducer , 
        communication : communicationReducer ,
        imagePopup : imagePopupReducer
    },
});

export default store;