import ItemNotFound from 'components/global/ItemNotFound';
import Loader from 'components/global/Loader';
import LoadingButton from 'components/global/LoadingButton';
import Pagination from 'components/global/pagination';
import Axios from 'config/api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { toast } from 'react-toastify';
import { Tooltip } from 'react-tooltip';
import confirmBox from 'utils/confirmBox';
import fetcher from 'utils/fetcher';
import toastError from 'utils/toastError';

const NotificationsTable = () => {
    const [notifications ,setNotifications] = useState([]);
    const [currentPage , setCurrentPage] = useState(1);
    const [pages , setPages] = useState(0);
    const { user } = useSelector(state => state.auth);
    const [deleteLoading , setDeleteLoading] = useState(false);
    const [selectedItemId , setSelectedItemId] = useState(false);

    const queryKey = ['fetch-notifications' , currentPage];
    const { isLoading , data } = useQuery(queryKey , () => fetcher(`/notification?page=${currentPage}` , user));

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , page , pages }}} = data;
            setNotifications(docs);
            setCurrentPage(page);
            setPages(pages);
        }
    }, [data])


    const deleteNotificationHandler = async id => {
        const message = 'Are you sure? You want to delete this notification?';
        const onYesClick = async () => {
            try {
                setDeleteLoading(true);
                const { data : { data : { message } } } = await Axios.delete(`/notification/${id}` , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                toast.success(message);
                setDeleteLoading(false);
                setNotifications(prev => prev.filter(i => i._id !== id ))
            } catch (error) {
                setDeleteLoading(false);
                toastError(error);              
            }

        }
        confirmBox({ message , onYesClick });
    }

    return (
        isLoading
        ? 
            <Loader />
        :
        notifications?.length > 0
        ? 
            <div>
                <div className="shadow-bg overflow-x-auto rounded-lg pb-12 border border-gray-300">
                    <Table className="w-full table-auto overflow-x-auto">
                        <Thead className="border-b border-b-gray-300 text-sm">
                            <Tr className=' '>
                                <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                    Title
                                </Th>
                                <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                    Description
                                </Th>
                                <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                    Date
                                </Th>
                                <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                    Action
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody className='text-sm '>
                        {
                            notifications?.map((item , i) => (
                                <Tr
                                key={item?._id} 
                                className="border-b border-b-gray-300 transition duration-300 ease-in-out "
                                >
                                    <Td className=" px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                        {item?.title}
                                    </Td>
                                    <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                        {item?.description?.length > 50 ? item?.description?.slice(0,20) + '...' : item?.description}
                                        {/* Lorem ipsum dolor sit, amet adipisicing. */}
                                    </Td>                    
                                    <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                        {moment(item?.createdAt).format('DD MMM YYYY hh:mm A')}
                                    </Td>
                                    <Td className=" text-gray-900  px-6 py-4 whitespace-nowrap ">
                                        <div className="flex items-center justify-center gap-4 text-xl ">
                                            <Link 
                                            to={`/notifications/edit/${item?._id}`} 
                                            className='text-green-500' 
                                            data-tooltip-id='edit-notification'
                                            data-tooltip-content='Edit Notification'
                                            >
                                                <i className="uil uil-edit-alt"></i>
                                                <Tooltip id='edit-notification' style={{ fontSize : 15 }} />
                                            </Link>
                                            <button 
                                            className='text-red-500' 
                                            onClick={() => {
                                                setSelectedItemId(item?._id);
                                                deleteNotificationHandler(item?._id);
                                            }}
                                            disabled={deleteLoading}
                                            data-tooltip-id='delete-notification'
                                            data-tooltip-content='Delete Notification'
                                            >
                                                {
                                                    deleteLoading && selectedItemId === item?._id 
                                                    ? 
                                                        <ClipLoader size={15} />
                                                    : 
                                                        <i className="uil uil-trash"></i>
                                                } 
                                                <Tooltip id='delete-notification' style={{ fontSize : 15 }} />
                                            </button>
                                        </div>
                                    </Td>
                                </Tr>
                                ))
                        }
                        
                        </Tbody>
                    </Table>
                </div>
                <div className="mt-4">
                    <Pagination 
                    pageCount={pages}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    redux={false}
                    />
                </div>
            </div>
        : 
            <ItemNotFound message='No notification found.' />
    )
}

export default NotificationsTable