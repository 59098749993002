import { debounce } from 'lodash';
import { memo } from 'react';


const Search = ({ fetcher , timeout = 500 }) => {

    const handleInputChange = debounce((event) => {
        const value = event.target.value;
        fetcher(value);
    } , timeout );


    return (
        <div className='max-w-[400px] w-full flex items-center gap-4 border border-gray-300 focus-within:border-primary rounded-lg py-3 px-4 '>
            <i className="uil uil-search text-grayText"></i>
            <input 
            type="text" 
            placeholder='Search...' 
            className='outline-none border-none'
            onChange={handleInputChange}
            />
        </div>
    )
}

export default memo(Search)