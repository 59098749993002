import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import ItemNotFound from 'components/global/ItemNotFound'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import Search from 'components/global/Search'
import ProfileRequestsTable from 'components/profileRequests/ProfileRequestsTable'
import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setCurrentPage, setDocs, setDocsCount, setKeyword, setPages } from 'redux/reducers/profileRequestsReducer'
import fetcher from 'utils/fetcher'

const ProfileRequests = () => {
    const location = useLocation();
    const pathname = location.pathname.split('/')[2];
    const dispatch = useDispatch();

    const { 
        docs , currentPage , keyword 
    } = useSelector((state => state.profileRequest));
    const { user } = useSelector(state => state.auth);

    const queryKey = ['fetch-users' , currentPage , keyword , pathname ];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/user-details?page=${currentPage}&status=${pathname}&keyword=${keyword}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , pages , page , docsCount } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
            dispatch(setDocsCount(docsCount));
        }
    }, [data]);

    const searchFetcher = async (value) => {
        dispatch(setKeyword(value));
    }


    return (
        <Layout>
            <div className='flex items-center justify-between gap-4 sm:flex-row flex-col-reverse'>
                <Heading title={'Profile Requests'} showIcon={false} />
                <BackBtn />
            </div>
            <div className='mt-6'>
                <div>
                    <Search fetcher={searchFetcher} />
                </div>
                <div className='mt-4'>
                    {
                        isLoading
                        ? 
                            <Loader />
                        : 
                        docs?.length > 0 
                        ? 
                            <ProfileRequestsTable />
                        : 
                            <ItemNotFound message='No request found.' />

                    }
                </div>
            </div>
        </Layout>
    )
}

export default ProfileRequests