import Loader from 'components/global/Loader';
import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setDocs, setDocsCount, setPages , setCurrentPage} from 'redux/reducers/loanReducer';
import LoansTable from './LoansTable';
import ItemNotFound from 'components/global/ItemNotFound';
import fetcher from 'utils/fetcher';


const PrevLoanHistory = ({ userId , currentLoanId }) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs , currentPage , keyword  } = useSelector(state => state.loan);

    const queryKey = ['fetch-user-withdrawals' , currentPage  , keyword , currentLoanId];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/loan/history/${userId}?page=${currentPage}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , docsCount , page , pages } } } = data;
            dispatch(setDocsCount(docsCount));
            dispatch(setDocs(docs?.filter(doc => doc?._id !== currentLoanId )));
            dispatch(setPages(pages));
            dispatch(setCurrentPage(page));
        }
    }, [data]);

    return (
        <div>
            {
                isLoading 
                ? 
                    <Loader />
                : 
                docs?.length > 0 
                ? 
                    <LoansTable />
                : 
                    <ItemNotFound message='No Loan Found.' />
            }
        </div>
    )
}

export default PrevLoanHistory