import ImagePopup from 'components/global/ImagePopup';
import Input from 'components/global/Input'
import NameInput from 'components/global/NameInput'
import { baseURL } from 'config/api';
import moment from 'moment';
import { useEffect, useState } from 'react';
import Accordion from 'react-accordion-comp';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveImage, setShowImagePopup } from 'redux/reducers/imagePopupReducer';

const KycDetails = ({ docDetails }) => {
    const dispatch = useDispatch();
    const [show , setShow] = useState(false);

    const handleImageClick = (img) => {
        dispatch(setActiveImage(img));
        dispatch(setShowImagePopup(true));
    }

    return (
        <div className='shadow-bg p-4'>
            <div 
            className={`flex items-center justify-between cursor-pointer ${show ? 'pb-2 border-b border-b-gray-300' : ''} `}
            onClick={() => setShow(!show)}
            >
                <h4 className="heading-sm">
                    KYC Details
                </h4>
                <i className="uil uil-angle-down text-2xl"></i>
            </div>
            <Accordion isOpen={show} >
                <div className='flex flex-col gap-4 mt-4'>
                    <div className="flex gap-4 md:flex-row flex-col">
                        <Input 
                        label={'Name as per Adhar card'}
                        value={docDetails?.kyc?.name}
                        readOnly
                        />
                        <Input 
                        label={'Date Of Birth'}
                        value={moment(docDetails?.kyc?.DOB).format('DD MMM YYYY')}
                        readOnly
                        />
                    </div>
                    <div>
                        <Input 
                        label={'Adhar Card Number'}
                        value={docDetails?.kyc?.adharCardNumber}
                        readOnly
                        />
                    </div>
                    <div className='flex gap-4 md:flex-row flex-col'>
                        <div className="flex-1 form-row">
                            <label>Adhar Card Front Image</label>
                            <div 
                            className='w-full h-[250px] overflow-hidden rounded-md cursor-pointer' 
                            onClick={() => handleImageClick(baseURL + docDetails?.kyc?.adharCardFrontImage)}
                            >
                                <img 
                                src={baseURL + docDetails?.kyc?.adharCardFrontImage} 
                                alt={docDetails?.kyc?.name} 
                                className='rounded-md border border-gray-300 w-full h-full  object-cover hover:scale-105 duration-200 ease-in-out ' 
                                />
                            </div>
                        </div>
                        <div className="flex-1 form-row">
                            <label>Adhar Card Back Image</label>
                            <div 
                            className='w-full h-[250px] overflow-hidden rounded-md cursor-pointer' 
                            onClick={() => handleImageClick(baseURL + docDetails?.kyc?.adharCardBackImage)}
                            >
                                <img 
                                src={baseURL + docDetails?.kyc?.adharCardBackImage} 
                                alt={docDetails?.kyc?.name} 
                                className='rounded-md border border-gray-300 w-full h-full  object-cover hover:scale-105 duration-200 ease-in-out '
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex gap-4 md:flex-row flex-col">
                        <Input 
                        label={'Additional Identity Type'}
                        value={docDetails?.kyc?.additionIdentityType}
                        readOnly
                        />
                        <div className="flex-1 form-row">
                            <label className='capitalize'>
                                {docDetails?.kyc?.additionIdentityType}
                            </label>
                            <div 
                            className='w-full h-[250px] overflow-hidden rounded-md cursor-pointer' 
                            onClick={() => handleImageClick(baseURL + docDetails?.kyc?.additionalIdentityImage)}
                            >
                                <img 
                                src={baseURL + docDetails?.kyc?.additionalIdentityImage} 
                                alt={docDetails?.kyc?.additionIdentityType} 
                                className='rounded-md border border-gray-300 w-full h-full  object-cover hover:scale-105 duration-200 ease-in-out ' 
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Accordion>

        </div>
    )
}

export default KycDetails