import { createSlice } from "@reduxjs/toolkit";

const imagePopupSlice = createSlice({
    name : 'imagePopup' ,
    initialState : {
        showImagePopup : false ,
        activeImage : null 
    } , 
    reducers : {
        setShowImagePopup (state , action) {
            state.showImagePopup = action.payload
        } ,
        setActiveImage (state , action) {
            state.activeImage = action.payload
        } ,
    }
});

export const { 
    setShowImagePopup , setActiveImage
} = imagePopupSlice.actions;

export default imagePopupSlice.reducer;