import React, { useEffect, useState } from 'react';
import OtpInput from 'react-otp-input';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { resendOtp, verifyOtp } from 'redux/actions/authActions';

const VerifyOtp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading , resendLoading , user } = useSelector(state => state.auth);
    const [otp, setOtp] = useState('');
    const [disableResend, setDisableResend] = useState(true);
    const [resendTimer, setResendTimer] = useState(60);

    useEffect(() => {
        let timer;
        if (disableResend) {
            timer = setInterval(() => {
                setResendTimer(prevTimer => prevTimer - 1);
            }, 1000);
        }
        return () => clearInterval(timer);
    }, [disableResend]);

    useEffect(() => {
        if (resendTimer === 0) {
            setDisableResend(false);
            setResendTimer(60); // Reset timer to 60 seconds
        }
    }, [resendTimer]);

    const submitHandler = async (e) => {
        e.preventDefault();
        dispatch(verifyOtp(otp , navigate))
    }

    const resendHandler = () => {
        dispatch(resendOtp());
        setDisableResend(true);
    }

    return (
        <div className="fixed top-0 left-0 flex items-center justify-center w-full min-h-screen bg-gray-400">
            <div className='shadow-bg bg-pure p-4 sm:w-[600px] w-full'>
                <center>
                    <h1 className='text-2xl font-bold'>Verify OTP</h1>
                    <p className='text-gray-500 text-sm'>Otp sent to your email</p>
                </center>
                <form 
                className='mt-8'
                onSubmit={submitHandler}
                >
                    <div className='flex items-center justify-center'>
                        <OtpInput
                        inputType='number'
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        renderSeparator={<span></span>}
                        renderInput={(props) => <input {...props} className='h-[40px] rounded-md border  outline-none focus:border-primary text-black sm:mr-4 mr-2'/>}
                        inputStyle={{
                            width: 40 ,
                            height : 40
                        }}
                        required
                        />
                    </div>
                    <center className='mt-8'>
                        <button 
                        type='submit'
                        className="btn-primary py-2 sm:px-12 px-6"
                        disabled={loading || resendLoading || !otp}
                        >
                            {
                                loading 
                                ?
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Confirm'
                            }
                        </button>
                        <div className='flex items-center justify-center gap-1 text-sm text-grayText mt-4'>
                            <span>Did not get OTP? </span>
                            <button 
                            type='button'
                            className="text-dark" 
                            disabled={disableResend || resendLoading} 
                            onClick={resendHandler}
                            >
                                { resendLoading ? 'Sending...' : disableResend ? `Resend in ${resendTimer} seconds` : 'Resend' }
                            </button>
                        </div>
                    </center>
                </form>
                <div className='text-sm text-dark mt-4 text-center flex items-center justify-center gap-1'>
                    <button 
                    className=' underline'
                    onClick={() => navigate('/login')}
                    type='button'
                    >
                        Back to Login
                    </button>
                </div>
            </div>
        </div>
    );
}

export default VerifyOtp;