import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import shortNumberFormat from 'utils/ShortNumberFormat';
import formatAmount from 'utils/formatAmount';
import { Tooltip } from 'react-tooltip';
import UpdateTotalAmountPopup from './UpdateTotalAmountPopup';


const Cards = ({ loanPortfolio , setLoanPortfolio }) => {
    const [show , setShow] = useState(false);

    return (
        <div className='flex flex-wrap items-center gap-6'>
            <div className='flex-1 flex flex-col gap-4 bg-[#F3E8FF] rounded-lg p-4'>
                <div className='flex justify-between'>
                    <div className='bg-purple-500 rounded-full w-[40px] h-[40px] flex items-center justify-center text-2xl text-white'>
                        <i className="uil uil-receipt"></i>
                    </div>
                    <div className="w-[35px] h-[35px]  flex items-center justify-center rounded-full text-lg bg-pure hover:bg-primary hover:text-pure cursor-pointer duration-200"
                    data-tooltip-id='edit-amount'
                    data-tooltip-content='Update amount'
                    onClick={() => setShow(true)}
                    >
                        <i className="uil uil-pen"></i>
                        <Tooltip id='edit-amount' style={{ fontSize : 15}} />
                    </div>
                </div>
                <div className='text-2xl font-bold text-blueText mt-2'>
                    ₹ {shortNumberFormat(loanPortfolio?.totalAmount)}
                </div>
                <h4 className='font-medium'>Total Loan Amount</h4>
            </div>
            
            <div className='flex-1 flex flex-col gap-4 bg-[#FFF4DE] rounded-lg p-4'>
                <div className='flex justify-between'>
                    <div className='bg-orange-500 rounded-full w-[40px] h-[40px] flex items-center justify-center text-2xl text-white'>
                        <i className="uil uil-usd-circle"></i>
                    </div>
                    <Link 
                    to='/transactions/open-loans?status=disbursed' 
                    className='sm:text-base text-blue-500 text-sm hover:underline'
                    >
                        View
                    </Link>
                </div>
                <div className='text-2xl font-bold text-blueText mt-2'>
                    ₹ {formatAmount(loanPortfolio?.totalDisbursed)}
                </div>
                <h4 className='font-medium'>Total Amount disbursed</h4>
            </div>
            <div className='flex-1 flex flex-col gap-4 bg-[#FFE2E5] rounded-lg p-4'>
                <div className='flex justify-between'>
                    <div className='bg-pink-500 rounded-full w-[40px] h-[40px] flex items-center justify-center text-2xl text-white'>
                        <i className="uil uil-analytics"></i>
                    </div>
                </div>
                <div className='text-2xl font-bold text-blueText mt-2'>
                    ₹ {formatAmount(loanPortfolio?.remainingAmount)}
                </div>
                <h4 className='font-medium'>Remaining Loan Amount</h4>
            </div>
            <div className='flex-1 flex flex-col gap-4 bg-[#DCFCE7] rounded-lg p-4'>
                <div className='flex justify-between'>
                    <div className='bg-green-500 rounded-full w-[40px] h-[40px] flex items-center justify-center text-2xl text-white'>
                        <i className="uil uil-exclamation-octagon"></i>
                    </div>
                    <Link 
                    to='/transactions/over-due?status=overdue' 
                    className='sm:text-base text-blue-500 text-sm hover:underline'
                    >
                        View
                    </Link>
                </div>
                <div className='text-2xl font-bold text-blueText mt-2'>
                    {formatAmount(loanPortfolio?.totalNPA)}
                </div>
                <h4 className='font-medium'>Total NPA</h4>
            </div>

            {
                show && <UpdateTotalAmountPopup
                setShowPopup={setShow}
                loanPortfolio={loanPortfolio}
                setLoanPortfolio={setLoanPortfolio}
                />
            }
        </div>
    )
}

export default Cards;