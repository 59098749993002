import AvatarImg from 'assets/images/avatar.png';
import RequestStatus from 'components/global/RequestStatus';
import { baseURL } from 'config/api';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveImage, setShowImagePopup } from 'redux/reducers/imagePopupReducer';

const SelfieImage = () => {
    const dispatch = useDispatch();
    const { docDetails } = useSelector(state => state.profileRequest)

    const handleImageClick = (img) => {
        dispatch(setActiveImage(img));
        dispatch(setShowImagePopup(true));
    }

    return (
        <div className='shadow-bg rounded-md p-4'>
            <div className="flex items-center justify-between">
                <h3 className='heading-sm'>
                    Selfie Image
                </h3>
                <RequestStatus status={docDetails?.status} />
            </div>
            <center className='flex items-center justify-center '>
                <div 
                className='w-full h-[250px] overflow-hidden rounded-md cursor-pointer' 
                onClick={() => handleImageClick(docDetails?.user?.image ? baseURL + docDetails?.user?.image : AvatarImg)}
                >
                    <img 
                    src={docDetails?.user?.image ? baseURL + docDetails?.user?.image : AvatarImg} 
                    alt="Avatar" 
                    className='w-[250px] rounded-md h-[250px] object-cover hover:scale-105 duration-200 ease-in-out ' 
                    />
                </div>
            </center>
        </div>
    )
}

export default SelfieImage