import ItemNotFound from 'components/global/ItemNotFound'
import Loader from 'components/global/Loader'
import PopupLayout from 'components/global/PopupLayout'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { setShowContacts } from 'redux/reducers/userReducer'
import fetcher from 'utils/fetcher'

const UserContactsPopup = () => {
    const { user } = useSelector(state => state.auth);
    const { selectedUserId } = useSelector(state => state.user);
    const [doc , setDoc ] = useState(false);


    const queryKey = ['fetch-user-contacts' , selectedUserId];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/contact/${selectedUserId}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { doc } } } = data;
            setDoc(doc);
        }
    } , [data])


    return (
        <PopupLayout
        setShowPopup={setShowContacts}
        width={'lg:w-[50%] md:w-[60] sm:w-[80%] w-full'}
        redux
        >
           <center>
                <h3 className="heading">
                    Contacts
                </h3>
            </center>
            {
                isLoading
                ? 
                    <Loader />
                : 
                doc && doc?.contacts?.length > 0 
                ? 
                    <div className="mt-4 grid md:grid-cols-2 grid-cols-1 gap-4 max-h-[400px] overflow-auto py-4">
                        {
                            doc?.contacts?.map(item => (
                                <div
                                key={item}
                                className='rounded-md bg-gray-100 p-4 shadow-md hover:bg-gray-200 flex items-center gap-2 '
                                >
                                    <div className=''>
                                        <i className="uil uil-user-square text-black text-4xl"></i>
                                    </div>
                                    <div>
                                        <p className='text-sm'>{item?.name}</p>
                                        <p className='text-primary'>
                                            {item?.number}
                                        </p>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                : 
                    <ItemNotFound message='No Contact found.' />
            }
        </PopupLayout>
    )
}

export default UserContactsPopup