import Input from 'components/global/Input'
import moment from 'moment';
import { useEffect, useState } from 'react';
import Accordion from 'react-accordion-comp';

const PersonalDetails = ({ docDetails }) => {
    const [show , setShow] = useState(false);


    return (
        <div className='shadow-bg p-4'>
            <div 
            className={`flex items-center justify-between cursor-pointer ${show ? 'pb-2 border-b border-b-gray-300' : ''} `}
            onClick={() => setShow(!show)}
            >
                <h4 className="heading-sm">
                    Personal Details
                </h4>
                <i className="uil uil-angle-down text-2xl"></i>
            </div>
            <Accordion isOpen={show} >
                <div className='flex flex-col gap-4 mt-4'>
                    <div className="flex gap-4 md:flex-row flex-col">
                        <Input 
                        label={'Name '}
                        value={docDetails?.personalDetails?.name}
                        readOnly
                        />
                        <Input 
                        label={'Phone No '}
                        value={docDetails?.personalDetails?.phoneNumber}
                        readOnly
                        />
                    </div>
                    <div className="flex gap-4 md:flex-row flex-col">
                        <Input 
                        label={'Date Of Birth'}
                        value={moment(docDetails?.personalDetails?.DOB).format('DD MMM YYYY')}
                        readOnly
                        />
                        <Input 
                        label={'Pan Card Id'}
                        value={docDetails?.personalDetails?.panCardId}
                        readOnly
                        />
                    </div>
                    <div className="flex gap-4 md:flex-row flex-col">
                        <Input 
                        label={'Father name as per KYC'}
                        value={docDetails?.personalDetails?.fatherName}
                        readOnly
                        />
                        <Input 
                        label={'Mother name as per KYC'}
                        value={docDetails?.personalDetails?.motherName}
                        readOnly
                        />
                    </div>
                    <div className="flex gap-4 md:flex-row flex-col">
                        <Input 
                        label={'Address'}
                        value={docDetails?.personalDetails?.currentAddress}
                        readOnly
                        />
                        <Input 
                        label={'City'}
                        value={docDetails?.personalDetails?.currentCity}
                        readOnly
                        />
                    </div>
                    <div className="flex gap-4 md:flex-row flex-col">
                        <Input 
                        label={'State'}
                        value={docDetails?.personalDetails?.currentState}
                        readOnly
                        />
                        <Input 
                        label={'Pin Code'}
                        value={docDetails?.personalDetails?.currentPinCode}
                        readOnly
                        />
                    </div>
                    <div className="flex gap-4 md:flex-row flex-col">
                        <Input 
                        label={'Payment Address'}
                        value={docDetails?.personalDetails?.paymentAddress}
                        readOnly
                        />
                        <Input 
                        label={'Payment City'}
                        value={docDetails?.personalDetails?.paymentCity}
                        readOnly
                        />
                    </div>
                    <div className="flex gap-4 md:flex-row flex-col">
                        <Input 
                        label={'Payment State'}
                        value={docDetails?.personalDetails?.paymentState}
                        readOnly
                        />
                        <Input 
                        label={'Payment Pin Code'}
                        value={docDetails?.personalDetails?.paymentPinCode}
                        readOnly
                        />
                    </div>

                </div>
            </Accordion>
        </div>
    )
}

export default PersonalDetails