import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import Layout from 'components/global/Layout';
import NotificationsTable from 'components/notifications/NotificationTable';
import React from 'react'
import { Link } from 'react-router-dom';

const Notifications = () => {

    
    return (
        <Layout>
            <BackBtn />
            <div className="flex items-center justify-between">
                <Heading title='Notifications' icon='bell' />
                <Link
                to='/notifications/send' 
                className="btn-primary py-2 px-8 flex items-center gap-2"
                >
                    <i className="uil uil-plus"></i>
                    <span>Send Notification</span>
                </Link>
            </div>
            <div className='mt-4'>
                <NotificationsTable />
            </div>
        </Layout>
    )
}

export default Notifications;