import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout';
import React, { useEffect } from 'react';
import { useState  } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'components/global/Loader';
import { ClipLoader } from 'react-spinners';
import { user_edit, user_getDetails } from 'redux/actions/userActions';
import { Form, Formik } from 'formik';
import editUserValidation from 'validations/editUserValidation';
import FormControl from 'components/global/form/FormControl';
import { toast } from 'react-toastify';

const initState = {
    username : '' ,
    email : '' ,
    phone : '' ,
    residentialAddress : ''
}

const EditUser = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const { updateLoading , docDetails , loading } = useSelector(state => state.user);
    const [userData , setUserData] = useState(initState);

    
    useEffect(() => {
        dispatch(user_getDetails(id));
    }, [id , dispatch])


    useEffect(() => {
        if(docDetails) {
            const { username , email , phone , residentialAddress } = docDetails
            setUserData({
                username , residentialAddress , email , phone 
            });
        }
    } , [docDetails]);

    
    const submitHandler = formData => {
        if(JSON.stringify(formData) === JSON.stringify(userData)) {
            return toast.info("You have not made any changes to save.")
        }
        const data = {...formData , phone : formData?.phone?.toString() }
        dispatch(user_edit(id , data , navigate));
    }

    return (
        <Layout>
            <div>
                <div className='flex items-center justify-between gap-4'>
                    <div>
                        <Heading title='Edit User' icon='pen' />
                    </div>
                    <div>
                        <BackBtn />
                    </div>
                </div>
                {
                    loading
                    ? 
                        <Loader />
                    : 
                        <Formik
                        initialValues={userData}
                        validationSchema={editUserValidation}
                        onSubmit={submitHandler}
                        enableReinitialize
                        >
                            {
                                formik => {
                                    return (
                                        <Form
                                        className='shadow-bg p-4 mt-4 flex flex-col gap-4'
                                        >
                                            <div className='flex sm:flex-row flex-col gap-6'>
                                                <FormControl
                                                control='input' 
                                                label='User Name'
                                                placeholder='Ex: John Doe'
                                                name='username'
                                                formik={formik}
                                                />
                                                <FormControl
                                                control='input' 
                                                type='email' 
                                                label='Email'
                                                placeholder='Ex: example@gmail.com'
                                                name='email'
                                                formik={formik}
                                                />
                                            </div>
                                            <div className='flex sm:flex-row flex-col gap-6'>
                                                <FormControl
                                                control='input' 
                                                type='number' 
                                                label='Phone'
                                                placeholder='Ex: 91839249283'
                                                name='phone'
                                                formik={formik}
                                                />
                                                <FormControl
                                                control='input' 
                                                label='Residential Address'
                                                placeholder='Enter residential address'
                                                name='residentialAddress'
                                                formik={formik}
                                                />
                                            </div>
                                            <div className='mt-4'>
                                                <button 
                                                type="submit" 
                                                className='btn-primary py-3 px-12'
                                                disabled={updateLoading || !formik.isValid}
                                                >
                                                    {
                                                        updateLoading 
                                                        ? 
                                                            <ClipLoader size={20} color='white' />
                                                        :   
                                                            'Update'
                                                    }    
                                                </button>
                                            </div>

                                        </Form>
                                    )
                                }
                            }

                        </Formik>

                }
            </div>
        </Layout>
    )
}

export default EditUser;