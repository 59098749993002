import React, { Children } from 'react'
import PopupLayout from './PopupLayout'
import { useSelector } from 'react-redux'
import { setShowImagePopup } from 'redux/reducers/imagePopupReducer'

const ImagePopup = () => {
    const { activeImage } = useSelector(state => state.imagePopup)

    return (
        <PopupLayout
        setShowPopup={setShowImagePopup}
        redux
        >
            <div className=''>
                <img 
                src={activeImage} 
                alt="details" 
                className='w-full h-full max-w-[90vw] max-h-[80vh] object-contain rounded-md'
                />
            </div>
        </PopupLayout>
    )
}



export default ImagePopup