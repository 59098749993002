import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import ItemNotFound from 'components/global/ItemNotFound';
import Layout from 'components/global/Layout';
import Loader from 'components/global/Loader';
import LoanInfo from 'components/transactions/LoanInfo';
import PrevLoanHistory from 'components/transactions/PrevLoanHistory';
import WithdrawBankDetails from 'components/transactions/WithdrawBankDetails';
import UserInfo from 'components/userManagement/userDetails/UserInfo';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';
import { loan_updateDoc } from 'redux/actions/loanActions';
import { setDocDetails } from 'redux/reducers/loanReducer';
import confirmBox from 'utils/confirmBox';
import fetcher from 'utils/fetcher';

const LoanDetails = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docDetails : loan , updateLoading } = useSelector(state => state.loan);


    const queryKey = ['fetch-loan-details' , id ];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/loan/${id}` , user)
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { doc } } } = data;
            dispatch(setDocDetails(doc));
        }
    } , [data]);   
    
    const updateHandler = (status) => {
        const message = `Are you sure? You want to ${status} this loan request?`;
        const onYesClick = () => {
            dispatch(loan_updateDoc(id , { status }))
        }
        confirmBox({ message , onYesClick })
    }

    return (
        <Layout>
            <div className="flex items-center justify-between sm:flex-row flex-col-reverse">
                <Heading title='Loan Details' showIcon={false} />
                <BackBtn />
            </div>
            {
                isLoading
                ? 
                    <Loader />
                : 
                loan 
                ? 
                    <>
                        <div className='flex flex-col gap-4 mt-6'>
                            <UserInfo userDetails={loan?.user} />
                            <div>
                                <LoanInfo loan={loan} />
                            </div>

                            <div>
                                <WithdrawBankDetails />
                            </div>
                            {
                                loan?.status === 'pending' && 
                                <div className='flex items-center gap-6 sm:flex-row flex-col mt-8 justify-end'>
                                    {
                                        updateLoading 
                                        ? 
                                            <ClipLoader size={20} />
                                        : 
                                            <>
                                                <button 
                                                className="bg-green-500 hover:bg-green-600 py-2 px-6 text-white rounded-md"
                                                onClick={() => updateHandler('disbursed')}
                                                >
                                                    Approve
                                                </button>
                                                <button 
                                                className="bg-red-500 hover:bg-red-600 py-2 px-6 text-white rounded-md"
                                                onClick={() => updateHandler('declined')}
                                                >
                                                    Declined
                                                </button>
                                            </>
                                    }
                                </div>
                            }
                            <div className='mt-10'>
                                <h3 className="heading-sm">
                                    Previous Loan History
                                </h3>
                                <div className="mt-2">
                                    <PrevLoanHistory 
                                    userId={loan?.user?._id}
                                    currentLoanId={loan?._id}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                : 
                    <ItemNotFound message='Loan request not found.' />
            }


        </Layout>
    )
}

export default LoanDetails