import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import LoadingButton from 'components/global/LoadingButton'
import FormControl from 'components/global/form/FormControl'
import Axios from 'config/api'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import fetcher from 'utils/fetcher'
import toastError from 'utils/toastError'
import notificationValidations from 'validations/notificationValidations'

const initValues = {
    title : '' , 
    description : '' , 
}

const EditNotification = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [loading , setLoading] = useState(false);
    const [notification , setNotification] = useState(initValues);
    const { user } = useSelector(state => state.auth);

    const queryKey = ['fetch-single-notification' , id];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/notification/${id}`)
    });

    useEffect(() => {
        if(data) {
            setNotification(data?.data?.data?.doc);
        }
    }, [data]);


    const handleSubmit = async (formData , { resetForm }) => {
        try {
            setLoading(true);
            const { data : { data : { message  } } } = await Axios.put(`/notification/${id}` , formData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            toast.success(message);
            navigate('/notifications');
            resetForm();
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <Layout>
            <div className="flex items-center justify-between">
                <Heading title='Edit Notificaion' icon='pen' />
                <BackBtn />
            </div>
            <Formik
            initialValues={notification}
            validationSchema={notificationValidations}
            onSubmit={handleSubmit}
            enableReinitialize
            >
                {
                    formik => {
                        return (
                            <Form className='shadow-bg p-4 gray-border flex flex-col gap-4 mt-4'>
                                <FormControl
                                control='input'
                                label='Title'
                                placeholder='Enter notification title'
                                name='title'
                                formik={formik}
                                />
                                <FormControl
                                control='textarea'
                                label='Description'
                                placeholder='Write about notification'
                                name='description'
                                formik={formik}
                                />
                                <div className="mt-4">
                                    <button 
                                    className="btn-primary py-2 px-12"
                                    type='submit'
                                    disabled={loading || !formik.isValid}
                                    >
                                        {
                                            loading 
                                            ? 
                                                <LoadingButton />
                                            : 
                                                'Update'
                                        }
                                    </button>
                                </div>
                            </Form>
                        )
                    }
                }
            </Formik>
        </Layout>
    )
}

export default EditNotification;