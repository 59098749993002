import CopyInput from 'components/global/CopyInput'
import React from 'react'
import { useSelector } from 'react-redux'

const WithdrawBankDetails = () => {
    const { docDetails : loan } = useSelector(state => state.loan);


    return (
        <div>
            <h3 className="heading-sm">
                Bank Details
            </h3>
            <div className='shadow-bg p-4 mt-2 grid md:grid-cols-2 grid-cols-1 gap-4'>
                <CopyInput 
                label={'Bank Name'}
                value={loan?.bankName}
                />
                <CopyInput 
                label={'Account No'}
                value={loan?.accountNo}
                />
                <CopyInput 
                label={'Account Holder'}
                value={loan?.accountHolder}
                />
                <CopyInput 
                label={'IFSC_code'}
                value={loan?.IFSC_code}
                />
            </div>
        </div>
    )
}

export default WithdrawBankDetails