import React from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import './App.css';
import './utilities.css';
import App from './App';
import DrawerContextProvider from 'context/DrawerContext';


import "react-datepicker/dist/react-datepicker.css";
import "react-accordion-comp/dist/styles.css";
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import 'react-tooltip/dist/react-tooltip.css'

import { Provider } from 'react-redux';
import store from 'redux/store';

// React Query
import { QueryClient, QueryClientProvider } from'react-query';
const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Router>
        <QueryClientProvider client={queryClient}>
            <Provider store={store} >
                <DrawerContextProvider>
                    <App />
                </DrawerContextProvider>
            </Provider>
        </QueryClientProvider>
    </Router>
);

