import Sidebar from "components/global/sidebar";
import { Routes , Route, useLocation, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import { useApi } from "config/api";
import ProtectedRoute from "ProtectedRoute";
import { useEffect } from "react";
import Dashboard from "pages/dashboard";
import Login from "pages/auth/Login";
import VerifyOtp from "pages/auth/VerifyOtp";
import Users from "pages/userManagement";
import UserDetails from "pages/userManagement/UserDetails";
import EditUser from "pages/userManagement/EditUser";
import ProfileRequests from "pages/profileRequests";
import ProfileRequestDetails from "pages/profileRequests/ProfileRequestDetails";
import Notifications from "pages/notifications";
import SendNotification from "pages/notifications/SendNotification";
import EditNotification from "pages/notifications/EditNotification";
import Profile from "pages/profile";
import Settings from "pages/settings";
import LoanDetails from "pages/transactions/LoanDetails";
import Communications from "pages/communications";
import Transactions from "pages/transactions";
import { useSelector } from "react-redux";
import LoanPortfolio from "pages/loanPortfolio";


function App() {
    const api = useApi();
    const { user } = useSelector(state => state.auth);
    const location = useLocation();

    useEffect(() => {
        window.scrollTo(0,0);
    } , [location.pathname]);

    return (
        <div>
            <ToastContainer 
                style={{fontSize: 15}}
                position="top-center"
                autoClose={3000}
                closeOnClick
                pauseOnHover
            />
            <Routes>
                <Route path='/' element={
                    <ProtectedRoute>
                        <Dashboard />
                    </ProtectedRoute>
                } />
                <Route 
                path='/login' 
                element={user ? <Dashboard /> : <Login />} 
                />
                <Route 
                path='/verify-otp' 
                element={user ? <Dashboard /> : <VerifyOtp />} 
                />
                <Route 
                path='/profile' 
                element={
                    <ProtectedRoute>
                        <Profile />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/user-management' 
                element={
                    <ProtectedRoute>
                        <Users />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/user-management/users/:id' 
                element={
                    <ProtectedRoute>
                        <UserDetails />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/user-management/edit/:id' 
                element={
                    <ProtectedRoute>
                        <EditUser />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/profile-requests/approved' 
                element={
                    <ProtectedRoute>
                        <ProfileRequests />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/profile-requests/pending' 
                element={
                    <ProtectedRoute>
                        <ProfileRequests />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/profile-requests/declined' 
                element={
                    <ProtectedRoute>
                        <ProfileRequests />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/profile-requests/details/:id' 
                element={
                    <ProtectedRoute>
                        <ProfileRequestDetails />
                    </ProtectedRoute>
                } 
                />
                <Route 
                path='/notifications'
                element={
                    <ProtectedRoute>
                        <Notifications />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/notifications/send'
                element={
                    <ProtectedRoute>
                        <SendNotification  />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/notifications/edit/:id'
                element={
                    <ProtectedRoute>
                        <EditNotification  />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/settings'
                element={
                    <ProtectedRoute>
                        <Settings />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/transactions/details/:id'
                element={
                    <ProtectedRoute>
                        <LoanDetails />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/transactions/withdrawals'
                element={
                    <ProtectedRoute>
                        <Transactions />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/transactions/paid-loans'
                element={
                    <ProtectedRoute>
                        <Transactions />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/transactions/open-loans'
                element={
                    <ProtectedRoute>
                        <Transactions />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/transactions/over-due'
                element={
                    <ProtectedRoute>
                        <Transactions />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/transactions/rejected'
                element={
                    <ProtectedRoute>
                        <Transactions />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/communications'
                element={
                    <ProtectedRoute>
                        <Communications />
                    </ProtectedRoute>
                }
                />
                <Route 
                path='/loan-portfolio'
                element={
                    <ProtectedRoute>
                        <LoanPortfolio />
                    </ProtectedRoute>
                }
                />
            </Routes>
        </div>
    );
}

export default App;