import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name : 'user' ,
    initialState : {
        docs : [] ,
        docDetails : null ,
        loading : false , 
        createLoading : false , 
        updateLoading : false , 
        deleteLoading : false ,
        docsCount : 0 ,
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 ,
        status : '' ,
        duration : '' ,
        keyword : '' ,
        blockLoading : false ,
        showContacts : false ,
        selectedUserId : null ,
    } , 
    reducers : {
        setDocs (state , action) {
            state.docs = action.payload
        } ,
        setDocDetails (state , action) {
            state.docDetails = action.payload
        } ,
        addDoc (state , action) {
            state.docs = state.docs.unshift(action.payload);
        } ,
        updateDoc (state , action) {
            state.docs = state.docs?.map(item => {
                if(item._id === action.payload._id) return action.payload;
                return item;
            })
        } ,
        updateMultiple (state , action) {
            const userIds = action.payload;
            state.docs = state.docs.map(user => {
                if(userIds.includes(user._id)){
                    return {
                        ...user , 
                        status : 'deleted' 
                    }
                }
                return user;
            })
        } ,
        removeDoc (state , action) {
            state.docs = state.docs.filter(i => i._id !== action.payload);
        } , 
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        
        setUpdateLoading (state , action ) {
            state.updateLoading = action.payload
        } ,
        setCreateLoading (state , action ) {
            state.createLoading = action.payload
        } ,
        setDeleteLoading (state , action ) {
            state.deleteLoading = action.payload
        } ,
        setBlockLoading (state , action ) {
            state.blockLoading = action.payload
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setPages (state , action) {
            state.pages = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } ,
        setStatus (state , action) {
            state.status = action.payload;
        } ,
        setKeyword (state , action) {
            state.keyword = action.payload;
        } ,
        setDuration (state , action) {
            state.duration = action.payload;
        } ,
        setShowContacts (state , action) {
            state.showContacts = action.payload;
        } ,
        setSelectedUserId (state , action) {
            state.selectedUserId = action.payload;
        }
    }
});

export const { 
    setDocs , setDocDetails , setLoading , setCreateLoading , setUpdateLoading , setDeleteLoading , setCurrentPage , setPages , updateDoc , removeDoc , setDocsCount , addDoc , setStatus , setKeyword , setDuration , setBlockLoading , updateMultiple , setShowContacts , setSelectedUserId
} = userSlice.actions;

export default userSlice.reducer;