import Cards from 'components/dashboard/Cards'
import LoanRequestsTable from 'components/dashboard/LoanRequestsTable'
import ItemNotFound from 'components/global/ItemNotFound'
import Layout from 'components/global/Layout'
import Loader from 'components/global/Loader'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
import fetcher from 'utils/fetcher'

const Dashboard = () => {
    const { user } = useSelector(state => state.auth);
    const [cardsData, setCardsData] = useState({});
    const [recentLoanRequests, setRecentLoanRequests] = useState([]);

    const queryKey = ['fetch-dashboard-data']
    const { isLoading, data } = useQuery(queryKey, () => {
        return fetcher(`/admin/dashboard`, user)
    });

    useEffect(() => {
        if (data) {
            const { data : { data : {
                pendingLoanCount, unpaidLoanCount, totalUsersCount, totalDisbursedAmount, recentLoanRequests
            }
            } } = data;
            setCardsData({
                pendingLoanCount, unpaidLoanCount, totalUsersCount, totalDisbursedAmount
            });
            setRecentLoanRequests(recentLoanRequests);
        }
    }, [data])



    return (
        <Layout>
            <h1 className="heading mb-4">
                Welcome back Admin 🤗
            </h1>
            {
                isLoading
                ? 
                    <Loader />
                : 
                    <>
                        <Cards 
                        data={cardsData}
                        />
                        <div className='mt-10'>
                            <h3 className='heading'>Recent Loan Requests</h3>
                            <div className="mt-3">
                                {
                                    recentLoanRequests?.length > 0 
                                    ? 
                                        <LoanRequestsTable loans={recentLoanRequests} />
                                    : 
                                        <ItemNotFound message='No recent request found.' />
                                }
                            </div>
                        </div>
                    </>
            }
        </Layout>
    )
}

export default Dashboard