import Axios from 'config/api';
import { setLoading, setUser , setUpdateLoading , setResendLoading } from 'redux/reducers/authReducer';
import toastError from 'utils/toastError';
import { toast } from 'react-toastify';


export const login = (data , navigate ) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const { data : { data : { message , isRegistered } } } = await Axios.post('/user/phone-login' , data );
        if(isRegistered) {
            localStorage.setItem('phone' , data?.phone );
            toast.success(message);
            navigate('/verify-otp');
        }else {
            toast.error('incorrect phone no.')
        }
        dispatch(setLoading(false));
    } catch (err) {
        dispatch(setLoading(false));
        console.log('login error' , err);
        toastError(err);
    }
}

export const resendOtp = () => async (dispatch) => {
    dispatch(setResendLoading(true))
    try {
        const phone = localStorage.getItem('phone');
        const { data : { data : { message } } } = await Axios.post('/user/resend-otp' , { phone } );
        dispatch(setResendLoading(false));
        toast.success(message);
    } catch (err) {
        dispatch(setResendLoading(false));
        toastError(err);
    }
}

export const verifyOtp = (otp , navigate  ) => async (dispatch) => {
    dispatch(setLoading(true))
    try {
        const { data : { data : { message , doc  } } } = await Axios.post('/user/verify-otp' , { otp } );
        if(!doc?.roles?.includes('admin') ) {
            return toast.error('You are not an authorized user.')
        }
        dispatch(setUser({...doc}));
        localStorage.setItem('user' , JSON.stringify({...doc}));
        localStorage.removeItem('phone');
        navigate('/');
        dispatch(setLoading(false));
        toast.success(message);
    } catch (err) {
        dispatch(setLoading(false));
        console.log('register error' , err);
        toastError(err);
    }
}



export const updateProfile = (updatedData) => async (dispatch , getState) => {
    dispatch(setUpdateLoading(true))
    const token = getState().auth.user.token;
    try {
        const { data : { data : { doc , message } } } = await Axios.put('/user/update-profile' , updatedData  , {
            headers : {
                Authorization : `Bearer ${token}`
            }
        });
        toast.success(message);
        dispatch(setUser({...doc , token }));
        localStorage.setItem('user' , JSON.stringify({...doc , token }));
        dispatch(setUpdateLoading(false))
    } catch (err) {
        dispatch(setUpdateLoading(false));
        console.log('Update Profile error' , err);
        toastError(err);
    }
}

export const logout = (navigate, showToast = true) => async(dispatch) => {
    dispatch(setLoading(true));
    try {
        await Axios('/user/logout');
        dispatch(setUser(null));
        localStorage.removeItem('user');
        dispatch(setLoading(false));
        navigate('/login');
        if(showToast){
            toast.success('Logged out successfully.')
        }
    } catch (err) {
        dispatch(setLoading(false));
        console.log('logout error' , err);
        toastError(err);
    }
}