import * as Yup from 'yup';

const settingsValidation = Yup.object().shape({
    penaltyCharge : Yup.number().required('Penalty charge is required.') ,
    contactNo : Yup.string()
    .min(10, 'Phone should have at least 10 numbers')
    .max(10, 'Phone should not exceed 10 numbers')
    .required('Phone is required')
    .matches(
        /^[6-9]\d{9}$/, 
        'Invalid phone no.'
    ) ,
    minLoanLimit : Yup.number().required('Minimum loan limit is required.') , 
    maxLoanLimit : Yup.number().required('Maximum loan limit is required.') , 
    processingFee :  Yup.number().required('Processing fee is required.') ,
    officeAddress : Yup.string() ,
});

export default settingsValidation;