import RequestStatus from 'components/global/RequestStatus';
import moment from 'moment';
import React, { useState } from 'react'
import Accordion from 'react-accordion-comp';
import { useSelector } from 'react-redux';
import formatAmount from 'utils/formatAmount';

const Installment = ({ index , item }) => {
    const [show , setShow] = useState(false);

    return (
        <div className='shadow-bg p-4'>
            <div 
            className={`flex items-center justify-between cursor-pointer ${show ? 'pb-2 border-b border-b-gray-300' : ''} `}
            onClick={() => setShow(!show)}
            >
                <h4 className="heading-sm">
                   installment {index}
                </h4>
                <i className="uil uil-angle-down text-2xl"></i>
            </div>
            <Accordion isOpen={show} >
                <div className='flex flex-col gap-4 mt-4'>
                    <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                        <h6 className='font-medium'>Amount</h6>
                        <p className={'text-primary'}>
                            {formatAmount(item?.amount)}
                        </p>
                    </div>
                    <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                        <h6 className='font-medium'>Due Date</h6>
                        <p className={'text-primary'}>
                            {moment(item?.dueDate).format('DD MMM YYYY hh:mm A')}
                        </p>
                    </div>
                    <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                        <h6 className='font-medium'>Status</h6>
                        <p className={'text-primary'}>
                            <RequestStatus status={item?.paid ? 'paid' : 'unPaid' } />
                        </p>
                    </div>
                    <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                        <h6 className='font-medium'>Penalty</h6>
                        <p className={'text-primary'}>
                            {formatAmount(item?.penalty)}
                        </p>
                    </div>
                    <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                        <h6 className='font-medium'>Total Payable</h6>
                        <p className={'text-primary'}>
                            {formatAmount(item?.totalPayableAmount)}
                        </p>
                    </div>
                    {
                        item?.paid && 
                        <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                            <h6 className='font-medium'>Paid At</h6>
                            <p className={'text-primary'}>
                                {moment(item?.paidAt).format('DD MMM YYYY hh:mm A')}
                            </p>
                        </div>
                    }
                </div>
            </Accordion>
        </div>
    )
}

const LoanInfo = ({ loan }) => {
    

    return (
        <div>
            <div>
                <h3 className="heading-sm">
                    Loan Info
                </h3>
                <div className='shadow-bg p-4 bg-pure mt-2'>
                    <div className='flex flex-col gap-4'>
                        <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                            <h6 className='font-medium'>Loan Amount</h6>
                            <p className={'text-primary'}>
                                {loan?.loanAmount}
                            </p>
                        </div>
                        <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                            <h6 className='font-medium'>Duration</h6>
                            <p className={'text-primary'}>
                                {loan?.duration} {loan?.duration > 1 ? 'Months' : "Month"}
                            </p>
                        </div>
                        <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                            <h6 className='font-medium'>Interest Rate</h6>
                            <p className={'text-primary'}>
                                {loan?.interestRate}%
                            </p>
                        </div>
                        <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                            <h6 className='font-medium'>Processing Fee</h6>
                            <p className={'text-primary'}>
                                {loan?.processingFee}
                            </p>
                        </div>
                        <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                            <h6 className='font-medium'>EMI</h6>
                            <p className={'text-primary'}>
                                {formatAmount(loan?.emi)}
                            </p>
                        </div>
                        <div className='flex items-center justify-between border-b pb-4 sm:text-base text-sm'>
                            <h6 className='font-medium'>Status</h6>
                            <RequestStatus status={loan?.status} />
                        </div>
                    </div>
                </div>
            </div>
            {
                loan?.repaymentSchedule?.length > 0 
                ?
                <div className='mt-4'>
                    <h3 className="heading-sm">
                        Installments
                    </h3>
                    <div className='flex flex-col gap-4 mt-2'>
                        {
                            loan?.repaymentSchedule?.map((item,index) => (
                                <Installment   
                                // key={item?._id} 
                                key={item}
                                item={item} 
                                index={index + 1}
                                />
                            ))
                        }
                    </div>
                    
                </div>
                : 
                    ''
            }

        </div>
    )
}

export default LoanInfo