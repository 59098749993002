import { createSlice } from "@reduxjs/toolkit";

const profileRequest = createSlice({
    name : 'profileRequest' ,
    initialState : {
        docs : [] ,
        docDetails : null ,
        loading : false , 
        updateLoading : false , 
        deleteLoading : false ,
        docsCount : 0 ,
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 ,
        status : '' ,
        duration : '' ,
        keyword : '' ,
    } , 
    reducers : {
        setDocs (state , action) {
            state.docs = action.payload
        } ,
        setDocDetails (state , action) {
            state.docDetails = action.payload
        } ,
        addDoc (state , action) {
            state.docs = state.docs.unshift(action.payload);
        } ,
        updateDoc (state , action) {
            state.docs = state.docs?.map(item => {
                if(item._id === action.payload._id) return action.payload;
                return item;
            })
        } ,
        removeDoc (state , action) {
            state.docs = state.docs.filter(i => i._id !== action.payload);
        } , 
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        
        setUpdateLoading (state , action ) {
            state.updateLoading = action.payload
        } ,
        setDeleteLoading (state , action ) {
            state.deleteLoading = action.payload
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setPages (state , action) {
            state.pages = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } ,
        setStatus (state , action) {
            state.status = action.payload;
        } ,
        setKeyword (state , action) {
            state.keyword = action.payload;
        } ,
        setDuration (state , action) {
            state.duration = action.payload;
        } ,
    
    }
});

export const { 
    setDocs , setDocDetails , setLoading , setUpdateLoading , setDeleteLoading , setCurrentPage , setPages , updateDoc , removeDoc , setDocsCount , addDoc , setStatus , setKeyword , setDuration 
} = profileRequest.actions;

export default profileRequest.reducer;