import { createSlice } from "@reduxjs/toolkit";

const loanSlice = createSlice({
    name : 'loan' ,
    initialState : {
        docs : [] ,
        docDetails : null ,
        loading : false , 
        currentPage : 1 ,
        pages : 1 , 
        docsCount : 0 , 
        keyword : '' ,
        status : '' ,
        updateLoading : false , 
        deleteLoading : false ,
    } , 
    reducers : {
        setDocs (state , action) {
            state.docs = action.payload
        } ,
        setDocDetails (state , action) {
            state.docDetails = action.payload
        } ,
        updateDoc (state , action) {
            const index = state.docs.findIndex(i => i._id === action.payload._id);
            state.docs[index] = action.payload;
            state.docDetails = action.payload;
        } ,
        deleteDoc (state , action) {
            state.docs = state.docs.filter(i => i._id !== action.payload);
        } , 
        setLoading (state , action) {
            state.loading = action.payload;
        } ,
        setUpdateLoading (state , action) {
            state.updateLoading = action.payload;
        } ,
        setDeleteLoading (state , action) {
            state.deleteLoading = action.payload;
        } ,
        setCurrentPage (state , action) {
            state.currentPage = action.payload;
        } ,
        setPages (state , action) {
            state.pages = action.payload;
        } ,
        setDocsCount (state , action) {
            state.docsCount = action.payload;
        } ,
        setKeyword (state , action) {
            state.keyword = action.payload;
        } ,
        setStatus (state , action) {
            state.status = action.payload;
        } 
    }
});

export const { 
    setDocs , setDocDetails , setLoading , setCurrentPage , setPages , setDocsCount , setFrom , setTo , setKeyword , updateDoc , deleteDoc , setUpdateLoading , setDeleteLoading , setStatus
} = loanSlice.actions;

export default loanSlice.reducer;