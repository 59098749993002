import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import Pagination from 'components/global/pagination'
import AvatarImg from 'assets/images/avatar.png';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import fetcher from 'utils/fetcher';
import { useEffect, useState } from 'react';
import { setCurrentPage, setDocs, setPages, setStatus, updateDoc } from 'redux/reducers/communicationReducer';
import Axios, { baseURL } from 'config/api';
import { defaultUserImg } from 'utils/default';
import RequestStatus from 'components/global/RequestStatus';
import Loader from 'components/global/Loader';
import ItemNotFound from 'components/global/ItemNotFound';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import confirmBox from 'utils/confirmBox';
import { ClipLoader } from 'react-spinners';


const Item = ({ doc }) => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const [closeLoading , setCloseLoading] = useState(false);

    const closeCaseHandler = async (caseId) => {
        const message = 'Are you sure? You want close this case?';
        const onYesClick = async () => {
            try {
                setCloseLoading(true);
                const body = { status : 'closed' };
                const { data : { data : { message , doc } } } = await Axios.put(`/communication/${caseId}` , body , {
                    headers : {
                        Authorization : `Bearer ${user?.token}`
                    }
                });
                toast.success(message);
                dispatch(updateDoc(doc));
                setCloseLoading(false);
            } catch (error) {
                setCloseLoading(false);
                toastError(error);
            }
        }

        confirmBox({ message , onYesClick });
    }


    return (
        <div className='shadow-bg p-4 hover:border-primary'>
            <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                    <img 
                    src={doc?.user?.image ? baseURL + doc?.user?.image : defaultUserImg}
                    alt="" 
                    className='w-[50px] h-[50px] object-cover rounded-full' 
                    />

                    <div className=''>
                        <p>
                            {doc?.user?.username}
                        </p>
                        <span className='text-sm text-gray-500'>
                            {doc?.user?.phone}
                        </span>
                    </div>
                </div>
                <div className='flex items-center gap-4 text-xs'>
                    <RequestStatus status={doc?.status} />
                    <Link to={`/user-management/users/${doc?.user?._id}`} className="btn-secondary py-2 px-4">
                        View Profile
                    </Link>
                    {
                        doc?.status === 'open' && 
                        <button 
                        className="btn-primary py-2 px-4"
                        onClick={() => closeCaseHandler(doc?._id)}
                        disabled={closeLoading}
                        >
                            {
                                closeLoading
                                ? 
                                    <ClipLoader size={20} color='white' />
                                : 
                                    'Close Case'
                            }
                        </button>
                    }
                </div>
            </div>
            <div className='mt-4 border-t pt-2'>
                <h3 className='text-lg font-semibold'>
                    {doc?.title}
                </h3>
                <p className="mt-2 text-gray-600">
                    {doc?.description}
                </p>
            </div>
        </div>

    )
}

const Communications = () => {
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs , pages , currentPage , status } = useSelector(state => state.communication);
    const [closeLoading , setCloseLoading] = useState(false);

    const queryKey = ['fetch-communications' , status , currentPage]
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/communication?status=${status}&page=${currentPage}` , user)
    });


    useEffect(() => {
        if(data) {
            const { data : { data : { docs , page , pages } } } = data;
            dispatch(setDocs(docs));
            dispatch(setCurrentPage(page));
            dispatch(setPages(pages));
        }
    } , [data]);

    

    return (
        <Layout>
            <BackBtn />
            <div className="flex items-center justify-between sm:flex-row flex-col-reverse">
                <Heading title='Communications Module' showIcon={false} />
                <div>
                    <select
                    className='select-box'
                    value={status}
                    onChange={e => dispatch(setStatus(e.target.value))}
                    >
                        <option value="">All</option>
                        {
                            ['open' , 'closed'].map(item => (
                                <option
                                value={item}
                                className='capitalize'
                                >
                                    {item}
                                </option>
                            ))
                        }
                    </select>
                </div>
            </div>
            {
                isLoading
                ? 
                    <Loader />
                : 
                docs?.length > 0 
                ? 
                    <div className='flex flex-col gap-4 mt-4'>
                        {
                            docs?.map(item => (
                                <Item key={item?._id} doc={item}  />
                            ))
                        }
                        <div>
                            <Pagination 
                            pageCount={pages}
                            currentPage={currentPage}
                            setPage={setPages}
                            />
                        </div>
                    </div>
                : 
                    <ItemNotFound message='No communication case found.' />
            }
        </Layout>
    )
}

export default Communications