import BackBtn from 'components/global/BackBtn';
import Heading from 'components/global/Heading';
import Layout from 'components/global/Layout';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setDocs, setDocsCount, setPages , setCurrentPage, setKeyword} from 'redux/reducers/loanReducer';
import fetcher from 'utils/fetcher';
import Search from 'components/global/Search'
import Loader from 'components/global/Loader';
import ItemNotFound from 'components/global/ItemNotFound';
import LoansTable from 'components/transactions/LoansTable';
import { useLocation } from 'react-router-dom';



const Transactions = () => { // transactions means loan requests
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const status = searchParams.get('status');
    
    const dispatch = useDispatch();
    const { user } = useSelector(state => state.auth);
    const { docs , currentPage , keyword  } = useSelector(state => state.loan);

    const queryKey = ['fetch-user-withdrawals' , currentPage , status , keyword];
    const { isLoading , data } = useQuery(queryKey , () => {
        return fetcher(`/loan?keyword=${keyword}&page=${currentPage}&status=${status}` , user);
    });

    useEffect(() => {
        if(data) {
            const { data : { data : { docs , docsCount , page , pages } } } = data;
            dispatch(setDocsCount(docsCount));
            dispatch(setDocs(docs));
            dispatch(setPages(pages));
            dispatch(setCurrentPage(page));
        }
    }, [data]);

    const searchFetcher = (value) => {
        dispatch(setKeyword(value))
    }

    return (
        <Layout>
            <div className="flex items-center justify-between sm:flex-row flex-col-reverse">
                <Heading title='Withdraw Requests' showIcon={false} />
                <BackBtn />
            </div>
            <div className='mt-6'>
                <div>
                    <Search fetcher={searchFetcher} />
                </div>
                <div className="mt-4">
                    {
                        isLoading 
                        ? 
                            <Loader />
                        : 
                        docs?.length > 0 
                        ? 
                            <LoansTable />
                        : 
                            <ItemNotFound message='No Loan Found.' />
                    }
                </div>
            </div>
        </Layout>
    )
}

export default Transactions