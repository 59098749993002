import BackBtn from 'components/global/BackBtn'
import Heading from 'components/global/Heading'
import Layout from 'components/global/Layout'
import LoadingButton from 'components/global/LoadingButton';
import FormControl from 'components/global/form/FormControl';
import Axios from 'config/api';
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import toastError from 'utils/toastError';
import settingsValidation from 'validations/settingsValidation';
import * as Yup from 'yup';
import DurationsTable from './DurationsTable';
import AddDurationPopup from './AddDurationPopup';
import { useQuery } from 'react-query';
import fetcher from 'utils/fetcher';
import Loader from 'components/global/Loader';
import ItemNotFound from 'components/global/ItemNotFound';

const initValues = {
    processingFee : '' , 
    penaltyCharge : '' , 
    contactNo : '' , 
    minLoanLimit : 0 ,
    maxLoanLimit : 0 ,
    officeAddress : '' ,
}


const Settings = () => {
    const { user } = useSelector(state => state.auth);
    const [loading , setLoading] = useState(false);
    const [settings , setSettings] = useState(initValues);
    const [showAddDurationPopup , setShowAddDurationPopup] = useState(false);

    const { isLoading , data } = useQuery('fetch-settings' , () => {
        return fetcher('/setting');
    } , { refetchOnWindowFocus : false });

    useEffect(() => {
        if(data){
            const { data : { data : { doc } } } = data;
            if(doc) {
                setSettings({...doc})
            }
        }
    }, [data]);


    const submitHandler = async formData => {
        if(JSON.stringify(formData) === JSON.stringify(settings)) {
            return toast.warn('You have not made any changes to save.')
        }
        try {
            setLoading(true);
            delete formData?.loanPortfolio;
            const { data : { data : { message, doc } } } = await Axios.post('/setting' , formData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            setSettings(doc);
            toast.success(message);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <Layout>
            <div className="flex items-center justify-between sm:flex-row flex-col-reverse">
                <Heading title='Settings' showIcon={false} />
                <BackBtn />
            </div>
            {
                isLoading
                ? 
                    <Loader />
                :
                    <Formik
                    initialValues={settings}
                    validationSchema={settingsValidation}
                    onSubmit={submitHandler}
                    enableReinitialize
                    >
                        {
                            (formik) => {
                                return (
                                    <Form className='mt-4 shadow-bg sm:py-8 sm:px-4 py-4 px-3 border border-gray-300'>
                                        <div className="flex gap-x-4 gap-y-2 sm:flex-row flex-col">
                                            <FormControl
                                            label='Processing Fee'
                                            control='input'
                                            type='number'
                                            placeholder='Enter processing fee amount'
                                            name='processingFee'
                                            formik={formik}
                                            />
                                            <FormControl
                                            control='input'
                                            label='Penalty Charge %'
                                            type='number'
                                            placeholder='Enter penalty charge in %'
                                            name='penaltyCharge'
                                            formik={formik}
                                            />
                                        </div>
                                        <div className="flex gap-x-4 gap-y-2 sm:flex-row flex-col mt-4 ">
                                            <FormControl
                                            label='Minimum Loan Limit'
                                            control='input'
                                            type='number'
                                            placeholder='Enter min loan limit amount'
                                            name='minLoanLimit'
                                            formik={formik}
                                            />
                                            <FormControl
                                            control='input'
                                            label='Maximum Loan LImit'
                                            type='number'
                                            placeholder='Enter max loan limit amount'
                                            name='maxLoanLimit'
                                            formik={formik}
                                            />
                                        </div>
                                        <div className="flex gap-6 sm:flex-row flex-col mt-4">
                                            <FormControl
                                            control='input'
                                            label='Contact No'
                                            placeholder='Enter your contact no'
                                            name='contactNo'
                                            formik={formik}
                                            />
                                            <FormControl
                                            control='input'
                                            label='Office Address'
                                            placeholder='Ex : abc Mall etc'
                                            name='officeAddress'
                                            formik={formik}
                                            />
                                        </div>
                                        <div className="mt-6">
                                            <button 
                                            className="btn-primary py-3 px-8"
                                            type='submit'
                                            disabled={loading || !formik.isValid}
                                            >
                                                {
                                                    loading 
                                                    ? 
                                                        <LoadingButton />
                                                    : 
                                                        'Save'
                                                }
                                            </button>
                                        </div>
                                    </Form>
                                )
                            }
                        }
                    </Formik>
            }

            <div className='mt-8'>
            <div className="flex items-center justify-between sm:flex-row flex-col-reverse">
                <Heading title='Durations and Interest Rates' showIcon={false} />
                <button 
                className='btn-primary py-2 px-6'
                onClick={() => setShowAddDurationPopup(true)}
                >
                    Add New Duration
                </button>
            </div>
                {
                    isLoading
                    ? 
                        <Loader />
                    :   
                    settings?.durations?.length > 0 
                    ?
                        <div className="mt-4">
                            <DurationsTable settings={settings} setSettings={setSettings}/>
                        </div>
                    : 
                        <ItemNotFound message='No duration added yet.' />
                }
            </div>
            {
                showAddDurationPopup && <AddDurationPopup setShowPopup={ setShowAddDurationPopup } setSettings={setSettings} />
            }
        </Layout>
    )
}

export default Settings