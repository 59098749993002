import RequestStatus from 'components/global/RequestStatus';
import moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import Pagination from 'components/global/pagination';
import confirmBox from 'utils/confirmBox';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentPage, setSelectedUserId, setShowContacts } from 'redux/reducers/userReducer';
import { user_edit } from 'redux/actions/userActions';
import { ClipLoader } from 'react-spinners';
import UserContactsPopup from './UserContactsPopup';

const UsersTable = () => {
    const dispatch = useDispatch();
    const { 
        docs : users , currentPage , pages , docsCount  , updateLoading , showContacts
    } = useSelector((state => state.user));


    const blockHandler = async (id , status) => {
        const message = `Are you sure? You want to ${status === 'active' || status === 'inActive' ? 'unblock' : 'block'} this user`;
        const onYesClick = async () => {
            await dispatch(user_edit(id, { status }))
        } 
        confirmBox({ message , onYesClick});
    }

    const deleteHandler = (userId , status) => {
        const message = `Are you sure? You want to ${status === 'active' || status === 'inActive' ? 'activate' : 'delete'} this user`;
        const onYesClick = async () => {
            await dispatch(user_edit(userId, { status }))
        } 
        confirmBox({ message , onYesClick});
    }

    return (
        <div>
            <div className="shadow-bg overflow-x-auto rounded-lg">
                <Table className="w-full table-auto overflow-x-auto">
                    <Thead className="border-b border-b-gray-300 text-sm text-primary">
                        <Tr className=' '>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Username/phone
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Email
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Joined
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Status
                            </Th>
                            <Th scope="col" className=" font-medium px-6 py-4 text-center">
                                Actions
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody className='text-sm '>
                    {
                        users?.map((item , i) => (
                            <Tr
                            key={item?._id} 
                            className="border-b border-b-gray-300 transition duration-300 ease-in-out "
                            >
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    <div className='flex flex-col gap-2'>
                                        <Link to={`/user-management/users/${item?._id}`} className='text-secondary underline'>
                                            {item?.username}
                                        </Link>
                                        <span>{item?.phone}</span>
                                    </div>
                                </Td>
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    {item?.email}
                                </Td>
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    {moment(item?.createdAt).format('DD MMM YYYY hh:mm A')}
                                </Td>                         
                                <Td className="px-6 py-4 whitespace-nowrap sm:text-center text-right">
                                    <div className="flex sm:items-center sm:justify-center justify-end">
                                        <RequestStatus status={item?.status} />
                                    </div>
                                </Td> 
                                <Td className=" text-gray-900  px-6 py-4 whitespace-nowrap ">
                                    {
                                        updateLoading
                                        ?  
                                            <ClipLoader size={20} />
                                        :
                                            <div className="flex items-center justify-center gap-4 text-xl ">

                                                <Link 
                                                to={`/user-management/users/${item?._id}`}
                                                className='text-primary' 
                                                data-tooltip-id="view-user-details"
                                                data-tooltip-content="View Details"
                                                >
                                                    <i className="uil uil-eye"></i>
                                                    <Tooltip 
                                                    id='view-user-details'
                                                    style={{ fontSize : 15 }} 
                                                    />
                                                </Link>
                                                <Link 
                                                to={`/user-management/edit/${item?._id}`} 
                                                className='text-green-500' 
                                                data-tooltip-id="edit-user"
                                                data-tooltip-content="Edit User"
                                                >
                                                    <i className="uil uil-edit-alt"></i>
                                                    <Tooltip 
                                                    id='edit-user'
                                                    style={{ fontSize : 15 }} 
                                                    />
                                                </Link>
                                                <button 
                                                className='text-cyan-500' 
                                                data-tooltip-id="view-contacts"
                                                data-tooltip-content={'View Contacts'}
                                                onClick={() => {
                                                    dispatch(setSelectedUserId(item?._id))
                                                    dispatch(setShowContacts(true));
                                                }}
                                                >
                                                    <i className="uil uil-phone"></i>
                                                    <Tooltip 
                                                    id='view-contacts'
                                                    style={{ fontSize : 15 }} 
                                                    />
                                                </button>
                                                <button 
                                                className='text-pink-500' 
                                                data-tooltip-id="block-user"
                                                data-tooltip-content={item?.status === 'blocked' ? 'Unblock User' : 'Block User'}
                                                onClick={() => blockHandler(
                                                    item?._id , 
                                                    item?.status === 'blocked' ? item?.userDetailsStatus === 'approved' ? 'active' : 'inActive' : 'blocked'
                                                )}
                                                >
                                                    <i className="uil uil-ban"></i>
                                                    <Tooltip 
                                                    id='block-user'
                                                    style={{ fontSize : 15 }} 
                                                    />
                                                </button>
                                                <button 
                                                className='text-red-500' 
                                                data-tooltip-id="delete-user"
                                                data-tooltip-content={item?.status === 'deleted' ? 'Undo delete' : 'Delete user'}
                                                onClick={() => deleteHandler(
                                                    item?._id , 
                                                    item.status === 'deleted' ? item?.userDetailsStatus === 'approved' ? 'active' : 'inActive' : 'deleted'
                                                )}
                                                >
                                                    <i className="uil uil-trash"></i>
                                                    <Tooltip 
                                                    id='delete-user'
                                                    style={{ fontSize : 15 }} 
                                                    />
                                                </button>
                                                
                                            </div>
                                    }
                                </Td>
                            </Tr>
                            ))
                    }
                    
                    </Tbody>
                </Table>
            </div>
            <div className="mt-2">
                <Pagination
                pageCount={pages}
                currentPage={currentPage}
                setPage={setCurrentPage}
                />
            </div>
            { showContacts && <UserContactsPopup  /> }
        </div>
    )
}

export default UsersTable