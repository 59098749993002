import PopupLayout from 'components/global/PopupLayout'
import FormControl from 'components/global/form/FormControl'
import Axios from 'config/api'
import { Form, Formik } from 'formik'
import { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { ClipLoader } from 'react-spinners'
import { toast } from 'react-toastify'
import toastError from 'utils/toastError'
import * as Yup from 'yup'

const initialValues = {
    amount : ''
}

const validations =Yup.object().shape({
    amount : Yup.number().required('amount is required.'),
})

const UpdateTotalAmountPopup = ({ setShowPopup , setLoanPortfolio , loanPortfolio  }) => {
    const { user } = useSelector(state => state.auth);
    const [loading , setLoading] = useState(false);
    const initialValues = {
        amount : loanPortfolio?.totalAmount || ''
    }

    const submitHandler = async (formData) => {
        try {
            setLoading(true);

            const { data : { data : { message, doc } } } = await Axios.put('/setting/update-loan-portfolio-amount' , formData , {
                headers : {
                    Authorization : `Bearer ${user?.token}`
                }
            });
            setLoanPortfolio(prev => ({
                ...prev ,
                totalAmount : doc?.totalAmount ,
                remainingAmount : doc?.remainingAmount
            }));
            toast.success(message);
            setShowPopup(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            toastError(error);
        }
    }

    return (
        <PopupLayout
        setShowPopup={setShowPopup}
        width={'md:w-[500px] w-full'}
        >
            <center>
                <h3 className="heading">
                    Update Amount
                </h3>
            </center>
            <div className="mt-6">
                <Formik
                initialValues={initialValues}
                validationSchema={validations}
                enableReinitialize
                onSubmit={submitHandler}
                >
                    {
                        formik => {
                            return (
                                <Form>
                                    <div className="flex flex-col gap-4">
                                        <FormControl
                                        label='Loan Portfolio Amount'
                                        control='input'
                                        type='number'
                                        placeholder='Enter loan portfolio amount'
                                        name='amount'
                                        formik={formik}
                                        />
                                    </div>
                                    <div className="mt-6">
                                        <button 
                                        className="btn-primary py-2 px-6"
                                        disabled={loading || !formik.isValid}
                                        type='submit'
                                        >
                                            {
                                                loading 
                                                ? 
                                                    <ClipLoader size={20} color='white' />
                                                : 
                                                    'Update'
                                            }
                                        </button>
                                    </div>
                                </Form>
                            )
                        }
                    }

                </Formik>
            </div>
        </PopupLayout>
    )
}

export default UpdateTotalAmountPopup