import React from 'react'
import { ClipLoader } from 'react-spinners'

const LoadingButton = () => {
    return (
        <p className='flex items-center gap-2 justify-center w-full text-white'>
            <ClipLoader size={16}  color='white' />
            <span>Loading...</span>
        </p>
    )
}

export default LoadingButton