import './styles.css';
import { useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDrawerContext } from 'context/DrawerContext';
import useClickOutside from 'utils/clickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from 'redux/actions/authActions';
import { ClipLoader } from 'react-spinners';
import logoImg from 'assets/images/logo.png'

const Sidebar = () => {
    const { loading } = useSelector(state => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const sidebarRef = useRef();
    const location = useLocation();
    const { showDrawer , setShowDrawer } = useDrawerContext();
    const [showProfileRequestDropMenus , setShowProfileRequestDropMenus] = useState(true);
    const [showTransactionsDropMenus , setShowTransactionsDropMenus] = useState(true);

    useClickOutside(sidebarRef , () => setShowDrawer(false))

    const isActive = (path , home) => {
        if (home) return location.pathname === '/' ;
        return location.pathname.split('/').includes(path);
    }

   const logoutHandler = () => {
        dispatch(logout(navigate));
   }

    return (
        <div className=''>
            {
                showDrawer && 
                <div className='fixed top-0 left-0 bg-gray-900 w-full h-screen bg-opacity-30 opacity-1 duration-300 z-10'>
                </div>
            }
            <div 
            className={`sidebar ${showDrawer ? 'show' : '' } fixed top-0 md:left-0 -left-[200%] w-[270px]  overflow-auto h-full py-4 z-50 border-r bg-pure`} 
            ref={sidebarRef}
            >
                <div className='overflow-auto'>
                    <div className='flex items-center justify-center border-b pb-4 text-gradient text-2xl font-bold'>
                        <Link to='/'>
                            <img src={logoImg} alt="Mousam Loans" className='w-[150px]' />                     
                        </Link>
                    </div>
                    <ul className='sideMenu-list mt-6 text-dark min-h-screen h-full'>
                        <li 
                        className={`${isActive('' , true) ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/'>   
                                <i className="uil uil-apps"></i>
                                <span>Dashboard</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('user-management') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/user-management'>   
                                <i className="uil uil-user"></i>
                                <span>User Management</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('profile-requests') ? 'active' : ''} sideMenu-item`}
                        >
                            <div onClick={() => setShowProfileRequestDropMenus(prev => !prev)}>   
                                <i class="uil uil-accessible-icon-alt"></i>
                                <span>Profile Requests</span>
                            </div>
                        </li>
                        {/* DROP MENU */}
                        {
                            showProfileRequestDropMenus && 
                            <ul className='dropMenu'>
                                <li className={`${isActive('approved') ? 'drop-active' : ''}
                                dropMenu-item
                                `}>
                                    <Link to='/profile-requests/approved'>
                                        <div className='dot'></div>
                                        <span>Approved</span>
                                    </Link>
                                </li>
                                <li className={`${isActive('pending') ? 'drop-active' : ''}
                                dropMenu-item
                                `}>
                                    <Link to='/profile-requests/pending'>
                                        <div className='dot'></div>
                                        <span>Pending</span>
                                    </Link>
                                </li>
                                <li className={`${isActive('declined') ? 'drop-active' : ''}
                                dropMenu-item
                                `}>
                                    <Link to='/profile-requests/declined'>
                                        <div className='dot'></div>
                                        <span>Declined</span>
                                    </Link>
                                </li>
                                
                            </ul>
                        }
                        <li 
                        className={`${isActive('transactions') ? 'active' : ''} sideMenu-item`}
                        >
                            <div onClick={() => setShowTransactionsDropMenus(prev => !prev)}>   
                                <i className="uil uil-chart-bar"></i>
                                <span>Transactions</span>
                            </div>
                        </li>
                        {
                            showTransactionsDropMenus && 
                            <ul className='dropMenu'>
                                <li className={`${isActive('withdrawals') ? 'drop-active' : ''}
                                dropMenu-item
                                `}>
                                    <Link to='/transactions/withdrawals?status=pending'>
                                        <div className='dot'></div>
                                        <span>Withdrawals</span>
                                    </Link>
                                </li>
                                <li className={`${isActive('paid-loans') ? 'drop-active' : ''}
                                dropMenu-item
                                `}>
                                    <Link to='/transactions/paid-loans?status=completed'>
                                        <div className='dot'></div>
                                        <span>Paid Loans</span>
                                    </Link>
                                </li>
                                <li className={`${isActive('open-loans') ? 'drop-active' : ''}
                                dropMenu-item
                                `}>
                                    <Link to='/transactions/open-loans?status=disbursed'>
                                        <div className='dot'></div>
                                        <span>Open Loans</span>
                                    </Link>
                                </li>
                                <li className={`${isActive('over-due') ? 'drop-active' : ''}
                                dropMenu-item
                                `}>
                                    <Link to='/transactions/over-due?status=overdue'>
                                        <div className='dot'></div>
                                        <span>Over Due</span>
                                    </Link>
                                </li>
                                <li className={`${isActive('rejected') ? 'drop-active' : ''}
                                dropMenu-item
                                `}>
                                    <Link to='/transactions/rejected?status=declined'>
                                        <div className='dot'></div>
                                        <span>Rejected</span>
                                    </Link>
                                </li>
                                
                            </ul>
                        }
                        <li 
                        className={`${isActive('communications') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/communications'>   
                                <i className="uil uil-chat"></i>
                                <span>Communications</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('loan-portfolio') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/loan-portfolio'>   
                                <i className="uil uil-tachometer-fast-alt"></i>
                                <span>Loan Portfolio</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('notifications') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/notifications'>   
                                <i className="uil uil-bell"></i>
                                <span>Notifications</span>
                            </Link>
                        </li>
                        <li 
                        className={`${isActive('settings') ? 'active' : ''} sideMenu-item`}
                        >
                            <Link to='/settings'>   
                                <i className="uil uil-setting"></i>
                                <span>Settings</span>
                            </Link>
                        </li>
                        <li 
                        className={`sideMenu-item signout`}
                        onClick={logoutHandler}
                        >
                            {
                                loading
                                ? 
                                    <ClipLoader size={20} color='black' />
                                : 
                                    <>
                                        <i className="uil uil-signout"></i>
                                        <span>Sign Out</span>
                                    </>
                            }
                        </li>
                    </ul>
                </div>
                
            </div>
        </div>
    )
}

export default Sidebar