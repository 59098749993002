import React from 'react'
import { Link } from 'react-router-dom';
import shortNumberFormat from 'utils/ShortNumberFormat';
import formatAmount from 'utils/formatAmount';


const Cards = ({ data }) => {
    return (
        <div className='flex flex-wrap items-center gap-6'>
            <div className='flex-1 flex flex-col gap-4 bg-[#F3E8FF] rounded-lg p-4'>
                <div className='flex justify-between'>
                    <div className='bg-purple-500 rounded-full w-[40px] h-[40px] flex items-center justify-center text-2xl text-white'>
                        <i className="uil uil-user-plus"></i>
                    </div>
                    <Link 
                    to='/user-management' 
                    className='sm:text-base text-blue-500 text-sm hover:underline'>
                        View
                    </Link>
                </div>
                <div className='text-2xl font-bold text-blueText mt-2'>
                    {shortNumberFormat(data?.totalUsersCount)}
                </div>
                <h4 className='font-medium'>Total Users</h4>
            </div>
            <div className='flex-1 flex flex-col gap-4 bg-[#FFE2E5] rounded-lg p-4'>
                <div className='flex justify-between'>
                    <div className='bg-pink-500 rounded-full w-[40px] h-[40px] flex items-center justify-center text-2xl text-white'>
                        <i className="uil uil-analytics"></i>
                    </div>
                    <Link 
                    to='/transactions/withdrawals?status=pending' 
                    className='sm:text-base text-blue-500 text-sm hover:underline'
                    >
                        View
                    </Link>
                </div>
                <div className='text-2xl font-bold text-blueText mt-2'>
                    {formatAmount(data?.pendingLoanCount)}
                </div>
                <h4 className='font-medium'>Pending Loan Requests</h4>
            </div>
            <div className='flex-1 flex flex-col gap-4 bg-[#FFF4DE] rounded-lg p-4'>
                <div className='flex justify-between'>
                    <div className='bg-orange-500 rounded-full w-[40px] h-[40px] flex items-center justify-center text-2xl text-white'>
                        <i className="uil uil-usd-circle"></i>
                    </div>
                    <Link 
                    to='/transactions/open-loans?status=disbursed' 
                    className='sm:text-base text-blue-500 text-sm hover:underline'
                    >
                        View
                    </Link>
                </div>
                <div className='text-2xl font-bold text-blueText mt-2'>
                    ₹ {formatAmount(data?.totalDisbursedAmount)}
                </div>
                <h4 className='font-medium'>Total Amount disbursed</h4>
            </div>
            <div className='flex-1 flex flex-col gap-4 bg-[#DCFCE7] rounded-lg p-4'>
                <div className='flex justify-between'>
                    <div className='bg-green-500 rounded-full w-[40px] h-[40px] flex items-center justify-center text-2xl text-white'>
                        <i className="uil uil-exclamation-octagon"></i>
                    </div>
                    <Link 
                    to='/transactions/over-due?status=overdue' 
                    className='sm:text-base text-blue-500 text-sm hover:underline'
                    >
                        View
                    </Link>
                </div>
                <div className='text-2xl font-bold text-blueText mt-2'>
                    {formatAmount(data?.unpaidLoanCount)}
                </div>
                <h4 className='font-medium'>Unpaid Loans</h4>
            </div>
        </div>
    )
}

export default Cards;