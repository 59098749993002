import RequestStatus from 'components/global/RequestStatus';
import { baseURL } from 'config/api';
import moment from 'moment';
import Avatar from 'assets/images/avatar.png';
import formatAmount from 'utils/formatAmount';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';



const UserInfo = ({ userDetails }) => {
    const navigate = useNavigate();

    return (
        <div>
            <div className="shadow-bg gray-border flex sm:flex-row flex-col sm:gap-10 gap-6">
                <div className="sm:flex-[0.25] flex gap-4 bg-primary p-4 rounded-tl-md rounded-bl-md text-pure sm:w-fit relative sm:pr-10">
                    <div>
                        <img 
                        src={userDetails?.image ? baseURL + userDetails?.image : Avatar} 
                        alt={userDetails?.username} 
                        className='border rounded-full border-pure w-[140px] h-[140px] object-cover'
                        />
                        <p className='text-xs font-medium mt-4'>
                            Joined : {moment(userDetails?.createdAt).format('DD MMM YYYY ')}
                        </p>

                    </div>
                    <div className='mt-4'>
                        <h4 className='font-medium capitalize'>
                            {userDetails?.username}
                        </h4>
                        <p className='text-sm text-gray-200'>
                            {userDetails?.phone}
                        </p>
                        <div className="mt-2">
                            <RequestStatus status={userDetails?.userDetailsStatus} />
                        </div>
                        {/* <div className='mt-2 text-sm capitalize'>
                            Role : {_user?.role}
                        </div> */}
                    </div>
                    <div className="absolute w-[35px] h-[35px] top-2 right-2 flex items-center justify-center rounded-full border border-pure text-lg hover:bg-pure hover:text-black cursor-pointer duration-200"
                    data-tooltip-id='edit-user'
                    data-tooltip-content='Edit User'
                    onClick={() => navigate(`/user-management/edit/${userDetails?._id}`)}
                    >
                        <i className="uil uil-pen"></i>
                        <Tooltip id='edit-user' style={{ fontSize : 15}} />
                    </div>
                </div>
                <div className="flex-[0.75] flex items-center justify-around gap-4 flex-wrap p-4">
                    <div className='flex items-center justify-center flex-col'>
                        <p className='text-2xl font-bold text-primary'>
                            {formatAmount(userDetails?.wallet?.totalLoanLimit)}    
                        </p>  
                        <p className='mt-1 text-sm font-medium'>Loan Limit</p>
                    </div>
                    <div className='flex items-center justify-center flex-col '>
                        <p className='text-2xl font-bold text-primary'>
                            {formatAmount(userDetails?.wallet?.remainingLoanLimit)}    
                        </p>  
                        <p className='mt-1 text-sm font-medium'>Remaining Limit</p>
                    </div>
                    <div className='flex items-center justify-center flex-col '>
                        <p className='text-2xl font-bold text-primary'>
                            {formatAmount(userDetails?.wallet?.totalWithdraw)}    
                        </p>  
                        <p className='mt-1 text-sm font-medium'>Total Withdraw</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserInfo