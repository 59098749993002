import Input from 'components/global/Input'
import { baseURL } from 'config/api';
import { useEffect, useState } from 'react';
import Accordion from 'react-accordion-comp';
import BusinessDetails from './professionalDetails/BusinessDetails';
import SelfEmployedDetails from './professionalDetails/SelfEmployedDetails';
import JobDetails from './professionalDetails/JobDetails';
import StudentDetails from './professionalDetails/StudentDetails';

const ProfessionalDetails = ({ docDetails }) => {
    const [show , setShow] = useState(false);

    return (
        <div className='shadow-bg p-4'>
            <div 
            className={`flex items-center justify-between cursor-pointer ${show ? 'pb-2 border-b border-b-gray-300' : ''} `}
            onClick={() => setShow(!show)}
            >
                <h4 className="heading-sm">
                    Professional Details
                </h4>
                <i className="uil uil-angle-down text-2xl"></i>
            </div>
            <Accordion isOpen={show} >
                {
                    docDetails?.professionalDetails?.occupationType === 'business'
                    ? 
                        <BusinessDetails docDetails={docDetails} />
                    : 
                    docDetails?.professionalDetails?.occupationType === 'self employed'
                    ? 
                        <SelfEmployedDetails docDetails={docDetails} />
                    :
                    docDetails?.professionalDetails?.occupationType === 'job'
                    ? 
                        <JobDetails docDetails={docDetails} />
                    : 
                    docDetails?.professionalDetails?.occupationType === 'student'
                    ? 
                        <StudentDetails docDetails={docDetails} />
                    : 
                        ''
                }
            </Accordion>
        </div>
    )
}

export default ProfessionalDetails